import { shallowCopyOf } from '~/Utils';
import { fetch, FetchError, POST_MULTIPART, toRequestParams } from './ReST';
import { Constants, getConfig, showLog, showStats } from './Config';
import WS from "./WS";

interface Configuration{
    base: string;
    transport: string;
    showStats: boolean;
    showLog: boolean;
    token: string | null;
    platform: string,
    platformVersion: string,
    appVersion: string,
}

const config: Configuration = getConfig();

export const setToken = (token: string) => config.token = token;
export const setPlatform = (platform: string) => config.platform = platform;
export const setPlatformVersion = (platformVersion: string) => config.platformVersion = platformVersion;
export const setAppVersion = (version: string) => config.appVersion = version;
export const setBasePath = (path: string) => config.base = path;
export const getWs = () => WS;
export const setTransport = (transport: string) => config.transport = transport;
export const closeWs = WS.close;


type Params = { [key: string]: any };
type UrlMaker = (params: Params) => string;
type Map = Record<any, any>;

const variablePatter = /\{(\w+)\}/g;
const createUrlMaker = (template: string, deletePathVars: boolean = false): UrlMaker => {
  const parts = [];
  const variables = [];

  let find;
  let prevIndex = 0;
  while ((find = variablePatter.exec(template)) !== null) {
    variables.push(find[1]);
    parts.push(template.substring(prevIndex, find.index));
    prevIndex = find.index + find[0].length;
  }
  if (prevIndex === 0)
    return () => template;

  parts.push(template.substring(prevIndex, template.length));

  const m = parts;
  const v = variables;
  return function (params: any) {
    const length = Math.max(m.length, v.length);
    let s = '';
    for (let i = 0; i < length; i++) {
      if (m[i] !== null)
        s += m[i];
      let param;
      if (params && v[i] !== null && (param = params[v[i]]) != null) {
        s += encodeURIComponent(param);
        if (deletePathVars)
          delete params[v[i]]
      }
    }
    return s;
  };
}

const lazy = <T extends Function>(f: ((...any) => T), ...args): any => {
  let result: T;
  return function (): any {
    if (!result)
      result = f.apply(null, args) as T

    return result.apply(null, arguments)
  };
}

type ProgressListener = (event: ProgressEvent) => {}
type CancelProvider = (cancel: () => {}) => {}

const createGET = <R, P>(template: string) => {
  const urlMaker: (UrlMaker) = lazy(createUrlMaker, template, true);
  return async (params: P = ({} as P)) => {
    params = shallowCopyOf(params || {}) as P;

    return fetch<R>(`${config.base}${urlMaker(params)}`, {
      params,
      token: config.token
    });
  };
};

const createPUT = <R, P, B>(template: string) => {
    const urlMaker: (UrlMaker) = lazy(createUrlMaker, template, true);
    return async (params: P = ({} as P), body: B = ({} as B)) => {

        return fetch<R>(`${config.base}${urlMaker(params)}`, {
            body,
            params,
            method: "PUT",
            token: config.token
        });
    };
};

const createPOST = <R, P, B>(template: string) => {
  const urlMaker: (UrlMaker) = lazy(createUrlMaker, template, true);
  return async (params: P = ({} as P), body: B = ({} as B)) => {

    return fetch<R>(`${config.base}${urlMaker(params)}`, {
      body,
      params,
      method: "POST",
      token: config.token
    });
  };
};
const createPOSTBinary = <R, P>(template: string) => {
  const urlMaker: (UrlMaker) = lazy(createUrlMaker, template);
  return async (params: P = ({} as P), onSuccess?: (r: R) => void, onError?: (e: FetchError, status: number) => void) => {
    params = shallowCopyOf(params || {}) as P;

    return fetch<R>(`${config.base}${urlMaker(params)}`, {
      params,
      onSuccess,
      onError,
      responseType: 'blob',
      method: "POST",
      token: config.token
    });
  };
};
const createMultipart = <R, P, B>(template: string) => {
  const urlMaker = createUrlMaker(template);
  return (params: P | B = ({} as P | B), body: B | P = ({} as B | P), timeout?: number, onProgress?: ProgressListener, provideCancel?: CancelProvider) => {
    params = shallowCopyOf(params || {}) as P & B;

    return POST_MULTIPART<R>(`${config.base}${urlMaker(params)}`, body, params, config.token, timeout, true, onProgress, provideCancel);
  };
};
const createDELETE = <R, P>(template: string) => {
  const urlMaker: (UrlMaker) = lazy(createUrlMaker, template, true);
  return async (params: P = ({} as P), onSuccess?: (r: R) => void, onError?: (e: FetchError, status: number) => void) => {
    params = shallowCopyOf(params || {}) as P;

    return fetch<R>(`${config.base}${urlMaker(params)}`, {
      params,
      method: "DELETE",
      onSuccess,
      onError,
      token: config.token
    });
  };
};

const exports = {
    Constants,
    setToken,
    setPlatform,
    setPlatformVersion,
    setAppVersion,
    setBasePath,
    setTransport,
    showLog,
    showStats,
    closeWs,
    getWs,

    //generated endpoints start
    downloadAttachmentData: createGET<null, { id: number, name: string }>('/attachments/{id}/download'),
    getAttachment: createGET<AttachmentRefsView, { id: number }>('/attachments/{id}'),
    getAttachmentThumbnail: createGET<null, { id: number }>('/attachments/{id}/thumbnail'),
    openAttachmentData: createGET<null, { id: number, name: string }>('/attachments/{id}/data'),
    zipAttachments: createGET<null, { name: string, ids: Array<number> }>('/attachments/zip'),
    createAttachment: createPOST<Attachment, {}, CreateAttachmentData>('/attachments'),
    updateAttachment: createPOST<Attachment, { id: number }, Attachment>('/attachments/{id}'),
    uploadAttachment: createMultipart<Attachment, { id: number, file: any, resize?: string }, {}>('/attachments/{id}/data'),
    uploadLandingPageAttachment: createMultipart<Attachment, { id: number, file: any }, {}>('/landingPages/{id}/attachments/data'),

    getChat: createGET<ChatUnreadView, { id: number }>('/chats/{id}'),
    getChatMessage: createGET<ChatMessageView, { id: number }>('/chats/messages/{id}'),
    getChatMessages: createGET<Array<ChatMessageView>, { id: number }>('/chats/{id}/messages'),
    getChatParticipantNotificationLevel: createGET<NotificationLevelEnum, { chatId: number }>('/chats/{chatId}/participants/getLevel'),
    getChatParticipants: createGET<Array<UserAccountPublicView>, { id: number }>('/chats/{id}/participants'),
    getChatUnreadCount: createGET<UnreadMessagesCount, { id: number }>('/chats/{id}/unreadCount'),
    getChats: createGET<Array<ChatUnreadView>, { search?: string, query?: string, orderBy?: string, order?: Order, offset?: number, limit?: number, notEmpty?: boolean, notEmptyIgnoreSystemMessage?: boolean, notEmptyEntityChat?: boolean, notEmptyEntityChatIgnoreSystemMessage?: boolean, user?: Array<number>, company?: Array<number>, showArchived?: boolean, internal?: boolean, showUnreadOnly?: boolean, showAllChats?: boolean }>('/chats'),
    getUnreadCountForAllChats: createGET<UnreadMessagesCount, {}>('/chats/unreadCount'),
    addChatParticipant: createPOST<Array<UserAccountPublicView>, { id: number }, AddChatParticipantRequest>('/chats/{id}/participants/add'),
    createChat: createPOST<ChatView, {}, CreateDirectChatRequest>('/chats'),
    createChatMessage: createPOST<ChatMessageView, { id: number }, NewMessage>('/chats/{id}/messages'),
    readAllChatMessages: createPOST<Array<ChatMessageReadMark>, { id: number }, {}>('/chat/{id}/readAll'),
    updateChat: createPOST<Chat, { id: number }, Chat>('/chats/{id}'),
    updateChatParticipant: createPOST<Object, { chatId: number }, updateClassBody>('/chats/{chatId}/participants/update'),
    removeChatParticipant: createDELETE<Array<UserAccountPublicView>, { id: number, userId: number }>('/chats/{id}/participants/{userId}'),

    getCompanies: createGET<Array<CompanyUsersView>, { query?: string, search?: string, company?: Array<number>, location?: Array<number>, buyer?: Array<number>, withEmptyBuyersOnly?: boolean, onlyMy?: boolean, inactive?: boolean, dateOnboardedFrom?: string, dateOnboardedTo?: string, orderBy?: string, order?: Order, offset?: number, limit?: number }>('/companies'),
    getCompany: createGET<CompanyUsersView, { id: number }>('/companies/{id}'),
    getCompanyLocations: createGET<Array<LocationView>, { id: number, query?: string, orderBy?: string, order?: Order, offset?: number, limit?: number, search?: string }>('/companies/{id}/locations'),
    getCompanyUsers: createGET<Array<UserAccountView>, { id: number, search?: string }>('/companies/{id}/users'),
    setCompanyAndLocationsCodes: createPOST<CompanyUsersView, { id: number }, CompanySetCodesRequest>('/companies/{id}/setCodes'),
    updateCompany: createPOST<CompanyUsersView, { id: number }, CompanyUpdateRequest>('/companies/{id}'),
    deleteCompany: createDELETE<null, { id: number }>('/companies/{id}'),

    getEmail: createGET<Email, { id: number }>('/emails/{id}'),
    getEmails: createGET<Array<Email>, { query?: string, search?: string, toUser?: number, createDateFrom?: string, createDateTo?: string, orderBy?: string, order?: Order, offset?: number, limit?: number }>('/emails'),
    resendEmail: createPOST<Email, { id: number }, {}>('/emails/{id}/resend'),

    data: createGET<null, { id: number }>('/images/{id}/data'),
    thumbnail: createGET<null, { id: number }>('/images/{id}/thumbnail'),
    thumbnailLarge: createGET<null, { id: number }>('/images/{id}/thumbnailLarge'),

    info: createGET<null, {}>('/info'),

    getLandingPage: createGET<LandingPage, { id: number }>('/landingPages/{id}'),
    getLandingPageAttachments: createGET<Array<Attachment>, { id: number }>('/landingPages/{id}/attachments'),
    getLandingPageByName: createGET<LandingPage, { name: string }>('/landingPages/byName/{name}'),
    getLandingPages: createGET<Array<LandingPage>, { query?: string, orderBy?: string, order?: Order, offset?: number, limit?: number }>('/landingPages'),
    createLandingPage: createPOST<LandingPage, {}, LandingPage>('/landingPages/'),
    updateLandingPage: createPOST<LandingPage, { id: number }, LandingPage>('/landingPages/{id}'),

    getLocation: createGET<LocationView, { id: number }>('/locations/{id}'),
    getLocations: createGET<Array<LocationView>, { type?: LocationType, query?: string, search?: string, location?: Array<number>, country?: Array<string>, port?: Array<number>, accountManager?: Array<number>, withEmptyAMOnly?: boolean, companyId?: Array<number>, onlyMy?: boolean, inactive?: boolean, orderBy?: string, order?: Order, offset?: number, limit?: number }>('/locations'),
    getLocationsByUser: createGET<Array<LocationView>, { id: number }>('/locations/byUser/{id}'),
    updateLocation: createPOST<LocationView, { id: number }, LocationUpdateRequest>('/locations/{id}'),

    getNotifications: createGET<Array<Notification>, { offset?: number, limit?: number, showUnreadOnly?: boolean }>('/notifications'),
    getNotificationsUnreadCount: createGET<UnreadNotificationsCount, {}>('/notifications/unreadCount'),
    deleteAllNotifications: createPOST<Object, {}, {}>('/notifications/deleteAll'),
    deleteNotification: createPOST<Object, { id: number }, {}>('/notifications/{id}/delete'),
    markAllNotificationsAsRead: createPOST<Object, {}, {}>('/notifications/readAll'),
    markAllNotificationsAsReadByTarget: createPOST<Object, { type: NotificationType, ref: NotificationRef, refId: string }, {}>('/notifications/read/{type}/{ref}/{refId}'),
    markNotificationAsRead: createPOST<Notification, { id: number }, {}>('/notifications/{id}/read'),

    getAllPorts: createGET<Array<Port>, { inactive?: boolean, type?: Array<PortType>, country?: Array<string>, search?: string, orderBy?: string, order?: Order, offset?: number, limit?: number }>('/ports'),
    getPortById: createGET<Port, { id: number }>('/ports/{id}'),
    addPort: createPOST<Port, {}, UpsertPortRequest>('/ports'),
    updatePort: createPOST<Port, { id: number }, UpsertPortRequest>('/ports/{id}'),

    exportSeaFreightCosts: createGET<ExportSeaFreightCostsData, { query?: string, orderBy?: string, order?: Order, search?: string, portOfLoading?: Array<number>, portOfDischarge?: Array<number>, onlyEmptyPrice?: boolean, inactive?: boolean, incotermLoading?: Array<string>, incotermDischarge?: Array<string>, costPer40From?: number, costPer40To?: number, transitTime?: number, currency?: Array<string>, category?: Array<string>, destinationType?: Array<string>, vendor?: string, validityDateFrom?: string, validityDateTo?: string, lastChangedFrom?: string, lastChangedTo?: string }>('/seaFreightCosts/export'),
    getSeaFreightCost: createGET<SeaFreightCostView, { id: number }>('/seaFreightCosts/{id}'),
    getSeaFreightCosts: createGET<Array<SeaFreightCostView>, { query?: string, orderBy?: string, order?: Order, offset?: number, limit?: number, search?: string, portOfLoading?: Array<number>, portOfDischarge?: Array<number>, onlyEmptyPrice?: boolean, inactive?: boolean, incotermLoading?: Array<string>, incotermDischarge?: Array<string>, costPer40From?: number, costPer40To?: number, transitTime?: number, currency?: Array<string>, category?: Array<string>, destinationType?: Array<string>, vendor?: string, validityDateFrom?: string, validityDateTo?: string, lastChangedFrom?: string, lastChangedTo?: string }>('/seaFreightCosts'),
    createSeaFreightCost: createPOST<SeaFreightCostView, {}, SeaFreightCostUpdateRequest>('/seaFreightCosts/'),
    importSeaFreightCosts: createPOST<Array<SeaFreightCostImportRow>, {}, Array<SeaFreightCostImportRow>>('/seaFreightCosts/bulk/import'),
    updateSeaFreightCost: createPOST<SeaFreightCostView, { id: number }, SeaFreightCostUpdateRequest>('/seaFreightCosts/{id}'),
    validateSeaFreightCostsImport: createPOST<Array<SeaFreightCostImportRow>, {}, Array<SeaFreightCostImportRow>>('/seaFreightCosts/import/validate'),
    deleteSeaFreightCost: createDELETE<SeaFreightCost, { id: number }>('/seaFreightCosts/{id}'),

    getTranslationContexts: createGET<Array<string>, {}>('/translations/context'),
    getTranslations: createGET<Array<Translation>, { search?: string, property?: string, offset?: number, limit?: number, orderBy?: string, order?: Order }>('/translations'),
    getTranslationsByContext: createGET<Array<Translation>, { context: string }>('/translations/byContext/{context}'),
    createTranslation: createPOST<Translation, {}, Translation>('/translations/'),
    updateTranslation: createPOST<Translation, { id: number }, Translation>('/translations/{id}'),
    deleteTranslation: createDELETE<Translation, { id: number }>('/translations/{id}'),

    acceptCall: createPOST<string, {}, AcceptVideoCallRequest>('/twilio/acceptCall'),
    callUser: createPOST<ChatMessage, {}, CallUserRequest>('/twilio/callUser'),
    declineCall: createPOST<string, {}, DeclineVideoCallRequest>('/twilio/declineCall'),
    endCall: createPOST<string, {}, EndCallRequest>('/twilio/endCall'),
    joinRoom: createPOST<JoinRoomResponse, {}, JoinRoomRequest>('/twilio/join'),

    getPublicUsers: createGET<Array<UserAccountPublicView>, {}>('/users/public'),
    getUser: createGET<UserAccountView, { id: number }>('/users/{id}'),
    getUserPublicInfo: createGET<UserAccountPublicView, { id: number }>('/users/{id}/public'),
    getUserUserPermissions: createGET<GroupPermissionArray, { id: number }>('/users/{id}/permissions'),
    getUsers: createGET<Array<UserAccountView>, { query?: string, search?: string, orderBy?: string, order?: Order, offset?: number, limit?: number, onlyMy?: boolean, company?: Array<number>, onlyInactive?: boolean, country?: Array<string>, userGroup?: Array<number>, lastSeenFrom?: string, lastSeenTo?: string, status?: Array<Registration>, dateRegisteredFrom?: string, dateRegisteredTo?: string }>('/users'),
    getUsersByPermissions: createGET<Array<UserAccountView>, { permissionTypes: Array<GroupPermission>, search?: string }>('/users/by/permissions'),

    getUserEmailSettings: createGET<UserEmailSettingsView, { id: number }>('/user-email-settings/{id}'),
    updateUserEmailSettings: createPOST<UserEmailSettingsView, { id: number }, UserEmailSettingsView>('/user-email-settings/{id}'),

    getUserGroup: createGET<UserGroupPermissionsView, { id: number }>('/groups/{id}'),
    getUserGroups: createGET<Array<UserGroupPermissionsView>, { query?: string, orderBy?: string, order?: Order, offset?: number, limit?: number }>('/groups'),
    getUserGroupsByPermission: createGET<Array<UserGroupPermissionsView>, { type: GroupPermission }>('/groups/byPermission/{type}'),
    createUserGroup: createPOST<UserGroupPermissionsView, {}, UserGroup>('/groups/'),
    updateUserGroup: createPOST<UserGroupPermissionsView, { id: number }, UserGroupPermissionsView>('/groups/{id}'),

    acceptInvitation: createGET<Object, { token: string }>('/users/invitation/{token}'),
    confirmEmail: createGET<Object, { token: string, ref: string, local: boolean }>('/users/confirmEmail/{token}'),
    getSelf: createGET<LoginResponse, {}>('/users/self'),
    validateResetPassword: createGET<Object, { token: string }>('/users/reset-password/{token}'),
    login: createPOST<LoginResponse, {}, LoginData>('/users/login'),
    loginAsUser: createPOST<LoginResponse, { id: number }, {}>('/users/{id}/login'),
    resetPassword: createPOST<Object, {}, ResetPasswordRequest>('/users/resetPassword'),
    setNewPassword: createPOST<Object, {}, SetNewPasswordRequest>('/users/setNewPassword'),
    updateUserPassword: createPOST<void, { id: number }, UpdatePasswordBody>('/users/{id}/updatePassword'),

    createUser: createPOST<UserAccountView, {}, CreateUserRequest>('/users'),
    resendConfirmationEmail: createPOST<Object, {}, ResendConfirmEmailRequest>('/users/resendConfirmEmail'),
    resendWelcomeMail: createPOST<Object, { id: number }, {}>('/users/{id}/resend-welcome-mail'),
    signup: createPOST<null, {}, SignupData>('/users/signup'),
    updateUser: createPOST<Object, { id: number }, UserAccountUpdate>('/users/{id}'),
    removeUser: createDELETE<Object, { id: number }>('/users/{id}'),

    getAddOnCosts: createGET<Array<AddOnCost>, { query?: string, search?: string, orderBy?: string, order?: Order, offset?: number, limit?: number }>('/v2/addOnCosts'),
    createAddOnCost: createPOST<AddOnCost, {}, CreateAddOnCostRequest>('/v2/addOnCosts'),
    updateAddOnCost: createPOST<AddOnCost, { id: number }, AddOnCostUpdate>('/v2/addOnCosts/{id}'),
    deleteAddOnCost: createDELETE<null, { id: number }>('/v2/addOnCosts/{id}'),

    importCustomer: createPOST<CustomerImportResultView, { customerCode: string }, {}>('/customers/import/{customerCode}'),

    getCustomerCredit: createGET<CustomerCreditView, { customerId: number }>('/v2/customers/{customerId}/credit'),

    getDutyCosts: createGET<Array<DutyCostView>, { query?: string, search?: string, orderBy?: string, order?: Order, inactive?: boolean, offset?: number, limit?: number }>('/v2/dutyCosts'),
    addDutyCost: createPOST<DutyCostView, {}, CreateDutyCostRequest>('/v2/dutyCosts'),
    updateDutyCost: createPOST<DutyCostView, { htsNumber: string }, UpdateDutyCostRequest>('/v2/dutyCosts/{htsNumber}'),

    getAllFeatureFlags: createGET<null, {}>('/v2/flags'),
    getEnabledFeatureFlags: createGET<Array<FeatureFlag>, {}>('/v2/flags/enabled'),
    updateFeatureFlags: createPOST<void, {}, Map>('/v2/flags'),

    getLength: createGET<Length, { id: number }>('/v2/lengths/{id}'),
    getLengths: createGET<Array<Length>, { query?: string, search?: string, orderBy?: string, inactive?: boolean, order?: Order, offset?: number, limit?: number }>('/v2/lengths'),
    getLengthsByProductGroup: createGET<Array<Length>, { groupId: number, query?: string, search?: string, orderBy?: string, order?: Order, offset?: number, limit?: number }>('/v2/lengths/byProductGroup/{groupId}'),
    createLength: createPOST<Length, {}, CreateLengthRequest>('/v2/lengths'),
    updateLength: createPOST<Length, { id: number }, LengthUpdate>('/v2/lengths/{id}'),
    deleteLength: createDELETE<null, { id: number }>('/v2/lengths/{id}'),

    getCompaniesOnboardedMetrics: createGET<Array<TimeSeriesCategoricalMetric>, { interval: string, from?: string, to?: string }>('/metrics/companies/onboarded'),
    getCompanyProductsMetrics: createGET<Array<TimeSeriesCategoricalMetric>, { interval: string, from?: string, to?: string, companyId?: number, shipToId?: number, productId?: number }>('/metrics/companies/products'),
    getLeadsToSalesMetrics: createGET<Array<TimeSeriesCategoricalMetric>, { interval: string, from?: string, to?: string }>('/metrics/leads/sales'),
    getNumberOfPurchaseOrderTransactionsMetrics: createGET<Array<TimeSeriesCategoricalMetric>, { interval: string, from?: string, to?: string, company?: number }>('/metrics/transactions/purchases'),
    getNumberOfSalesOrderTransactionsMetrics: createGET<Array<TimeSeriesCategoricalMetric>, { interval: string, from?: string, to?: string, company?: number }>('/metrics/transactions/sales'),
    getOffersCreatedMetrics: createGET<Array<TimeSeriesCategoricalMetric>, { interval: string, from?: string, to?: string, buyer?: number, createdBy?: number, vendor?: number }>('/metrics/offers/created'),
    getOffersToPurchaseOrdersMetrics: createGET<Array<TimeSeriesCategoricalMetric>, { interval: string, from?: string, to?: string, am?: number, company?: number }>('/metrics/offers/purchaseorders'),
    getOffersToQuotesMetrics: createGET<Array<TimeSeriesCategoricalMetric>, { interval: string, from?: string, to?: string, am?: number, company?: number }>('/metrics/offers/quotes'),
    getQuoteMetrics: createGET<Array<TimeSeriesCategoricalMetric>, { interval: string, from?: string, to?: string, buyerId?: number, managerId?: number }>('/metrics/quotes'),
    getQuotesToOrdersMetrics: createGET<Array<TimeSeriesCategoricalMetric>, { interval: string, from?: string, to?: string, am?: number, company?: number }>('/metrics/quotes/converted'),
    getRequestsToQuotesMetrics: createGET<Array<TimeSeriesCategoricalMetric>, { interval: string, from?: string, to?: string, am?: number, company?: number }>('/metrics/requests/converted'),
    getUserRegistrationMetrics: createGET<Array<TimeSeriesCategoricalMetric>, { interval: string, from?: string, to?: string }>('/metrics/users/registrations'),

    getExternalOfferChat: createGET<ChatUnreadView, { id: number }>('/v2/offers/{id}/chat/external'),
    getInternalOfferChat: createGET<ChatUnreadView, { id: number }>('/v2/offers/{id}/chat/internal'),
    getOfferChatId: createGET<ChatView, { id: number }>('/v2/offers/{id}/chat/id'),

    getOffer: createGET<OfferView, { id: number }>('/v2/offers/{id}'),
    getOfferAttachments: createGET<Array<Attachment>, { id: number }>('/v2/offers/{id}/attachments'),
    getOffers: createGET<Array<OfferView>, { query?: string, search?: string, status?: Array<OfferStatus>, shipFrom?: Array<number>, valueFrom?: string, valueTo?: string, dateIncoDestinationFrom?: string, dateIncoDestinationTo?: string, validityDateFrom?: string, validityDateTo?: string, quoteDateFrom?: string, quoteDateTo?: string, poCreateDateFrom?: string, poCreateDateTo?: string, createdDateFrom?: string, createdDateTo?: string, port?: Array<number>, buyer?: Array<number>, createdBy?: Array<number>, firstQuoteAM?: number, vendor?: Array<number>, follower?: Array<number>, incoterm?: Array<string>, product?: Array<number>, productGroup?: Array<number>, productType?: Array<string>, onlyMy?: boolean, onlyArchivedOrDelivered?: boolean, onlyArchivedOrDeliveredOrExpired?: boolean, withoutArchivedOrDelivered?: boolean, withExpired?: boolean, onlyExpired?: boolean, onlyNotLinkedToRFQ?: boolean, orderBy?: string, order?: Order, offset?: number, limit?: number }>('/v2/offers'),
    addOfferMember: createPOST<void, { id: number, userId: number }, {}>('/v2/offers/{id}/follow/{userId}'),
    confirmOffer: createPOST<OfferView, { id: number }, ConfirmOfferRequest>('/v2/offers/{id}/confirm'),
    createOffer: createPOST<OfferView, {}, CreateOfferRequest>('/v2/offers'),
    postOfferReadMark: createPOST<void, { offerId: number }, {}>('/v2/offers/{offerId}/read'),
    removeOfferMember: createPOST<void, { id: number, userId: number }, {}>('/v2/offers/{id}/unfollow/{userId}'),
    updateOffer: createPOST<OfferView, { id: number }, OfferUpdate>('/v2/offers/{id}'),
    updateOfferAttachments: createPOST<Array<Attachment>, { id: number }, Array<number>>('/v2/offers/{id}/attachments'),
    deleteOffer: createDELETE<null, { id: number }>('/v2/offers/{id}'),

    getMyOfferFeedback: createGET<OfferFeedback, { id: number }>('/v2/offers/{id}/feedback/my'),
    createOfferFeedback: createPOST<OfferFeedback, { id: number }, CreateOfferFeedbackRequest>('/v2/offers/{id}/feedback'),

    getOfferMatchingProductsForLocation: createGET<Array<OfferMatchedProduct>, { id: number, locationId: number }>('/v2/offers/{id}/matchingShipTos/{locationId}/products'),
    getOfferMatchingShipTos: createGET<Array<OfferMatchingResult>, { id: number, inMyProducts: boolean, offset?: number, limit?: number }>('/v2/offers/{id}/matchingShipTos'),
    createQuotesFromOffer: createPOST<Array<Quote>, { id: number }, Array<CreateQuoteFromOfferRequest>>('/v2/offers/{id}/quotes'),

    getOffersByStatusSummary: createGET<Array<OffersByStatusSummary>, { search?: string, status?: Array<OfferStatus>, shipFrom?: Array<number>, valueFrom?: string, valueTo?: string, dateIncoDestinationFrom?: string, dateIncoDestinationTo?: string, validityDateFrom?: string, validityDateTo?: string, port?: Array<number>, buyer?: Array<number>, vendor?: Array<number>, follower?: Array<number>, incoterm?: Array<string>, product?: Array<number>, productGroup?: Array<number>, productType?: Array<string>, onlyMy?: boolean, onlyArchivedOrDelivered?: boolean, onlyArchivedOrDeliveredOrExpired?: boolean, withoutArchivedOrDelivered?: boolean, withExpired?: boolean, onlyExpired?: boolean }>('/v2/offers/byStatus'),

    calculateQuoteItemPrice: createGET<ItemCalculatedPriceView, { productId: number, isInternalCustomer: boolean, quoteUOM: ProductV2UOM, currency: string, incotermDestination: string, shipToLocationId: number, shipToPortId: number, shipFromPortId: number, offerId: number, offerProductId: number, lengthId: Array<number> }>('/v2/quote/item/price/calculate'),
    getPriceCalculation: createGET<PriceCalculationResults, { productId: number, shipToLocationId: number, shipToPortId: number, shipFromPortId: number, incotermLoading: string, incotermDestination: string, costOffer: number, costOfferCurrency: string, offerUom: ProductV2UOM, lengthId: Array<number>, quoteUOM: ProductV2UOM, quantity: number, currency: string }>('/v2/price/calculate'),

    getProductGroup: createGET<ProductGroupView, { id: number }>('/v2/productGroups/{id}'),
    getProductGroupPublic: createGET<ProductGroupPublicView, { id: number }>('/v2/productGroups/{id}/public'),
    getProductGroups: createGET<Array<ProductGroupView>, { query?: string, search?: string, onlyMy?: boolean, orderBy?: string, order?: Order, offset?: number, limit?: number, group?: Array<string>, groupId?: Array<number>, category?: Array<string>, species?: Array<string>, surface?: Array<string>, finish?: Array<string>, grade?: Array<string>, dryness?: Array<string>, construction?: Array<string>, type?: Array<string>, productType?: string, classCode?: Array<string>, lengthCode?: Array<string> }>('/v2/productGroups'),
    getProductGroupsPublic: createGET<Array<ProductGroupPublicView>, { query?: string, search?: string, onlyMy?: boolean, orderBy?: string, order?: Order, offset?: number, limit?: number, group?: Array<string>, groupId?: Array<number>, category?: Array<string>, species?: Array<string>, surface?: Array<string>, finish?: Array<string>, grade?: Array<string>, dryness?: Array<string>, construction?: Array<string>, type?: Array<string>, classCode?: Array<string>, lengthCode?: Array<string>, productType?: string }>('/v2/productGroups/public'),
    updateProductGroup: createPOST<ProductGroupView, { id: number }, ProductGroupUpdate>('/v2/productGroups/{id}'),
    updateProductGroupPrices: createPOST<void, { id: number }, ProductGroupUpdatePrices>('/v2/productGroups/{id}/price'),

    getCompanyProducts: createGET<Array<MyProduct>, { companyId: number, query?: string, search?: string, productType?: string, offset?: number, limit?: number }>('/v3/products/companyProducts/{companyId}'),
    getCompanyProductsByLocation: createGET<Array<MyProduct>, { locationId: number, query?: string, search?: string, productType?: string, offset?: number, limit?: number }>('/v3/products/companyProducts/byLocation/{locationId}'),
    getMyProducts: createGET<Array<MyProduct>, { query?: string, search?: string, productType?: string, offset?: number, limit?: number }>('/v3/products/my'),
    getMyProductsByLocation: createGET<Array<MyProduct>, { locationId: number, query?: string, search?: string, productType?: string, offset?: number, limit?: number }>('/v3/products/my/{locationId}'),
    getNewCompanyProductSales: createGET<Array<NewProductSales>, { from: string, to: string, limit?: number, offset?: number, companyId?: number, locationId?: number, productId?: number }>('/v3/products/companyProducts/sales'),
    getProductFilters: createGET<Filters, { productType: string }>('/v3/products/filters/{productType}'),
    getProductFromMsr: createGET<ProductUfp, { code: string }>('/v3/products/from-msr/{code}'),
    getProductPublicV3: createGET<ProductPublicView, { id: number }>('/v3/products/{id}/public'),
    getProductV3: createGET<ProductUfp, { id: number }>('/v3/products/{id}'),
    getProductsCategories: createGET<Array<ProductCategoryView>, { search?: string, query?: string, group?: Array<string>, groupId?: Array<number>, category?: Array<string>, species?: Array<string>, surface?: Array<string>, finish?: Array<string>, grade?: Array<string>, dryness?: Array<string>, construction?: Array<string>, type?: Array<string>, classCode?: Array<string>, lengthCode?: Array<string> }>('/v3/products/categories'),
    getProductsPublicV3: createGET<Array<ProductPublicView>, { search?: string, query?: string, orderBy?: string, order?: Order, offset?: number, limit?: number, group?: Array<string>, groupId?: Array<number>, category?: Array<string>, species?: Array<string>, surface?: Array<string>, finish?: Array<string>, grade?: Array<string>, dryness?: Array<string>, construction?: Array<string>, type?: Array<string>, classCode?: Array<string>, lengthCode?: Array<string>, productType?: string, active?: boolean }>('/v3/products/public'),
    getProductsV3: createGET<Array<ProductUfp>, { search?: string, query?: string, orderBy?: string, order?: Order, offset?: number, limit?: number, group?: Array<string>, groupId?: Array<number>, category?: Array<string>, species?: Array<string>, surface?: Array<string>, finish?: Array<string>, grade?: Array<string>, dryness?: Array<string>, construction?: Array<string>, type?: Array<string>, classCode?: Array<string>, lengthCode?: Array<string>, productType?: string, active?: boolean }>('/v3/products'),
    addCatalogueProductsToCompany: createPOST<Array<MyProduct>, {}, AddCatalogueProductsToCompanyRequest>('/v3/products/catalogue/add'),
    createCompanyProduct: createPOST<MyProduct, {}, CreateCompanyProductRequest>('/v3/products/companyProduct'),
    createCompanyProducts: createPOST<Array<MyProduct>, {}, Array<CreateCompanyProductRequest>>('/v3/products/companyProducts'),
    createMyProducts: createPOST<Array<MyProduct>, {}, Array<CreateMyProductRequest>>('/v3/products/myProducts'),
    createProduct: createPOST<ProductUfp, {}, CreateProductRequest>('/v3/products'),
    updateMyProduct: createPOST<MyProduct, { id: number }, CompanyProductUpdate>('/v3/products/my/{id}'),
    updateProductV3: createPOST<ProductUfp, { id: number }, ProductV2>('/v3/products/{id}'),
    deleteCompanyProducts: createDELETE<null, { id: number }>('/v3/products/companyProducts/{id}'),

    getPurchaseOrder: createGET<PurchaseOrderView, { id: number }>('/v2/purchaseorders/{id}'),
    getPurchaseOrderItems: createGET<Array<PurchaseOrderItemView>, { id: number }>('/v2/purchaseorders/{id}/items'),
    getPurchaseOrders: createGET<Array<PurchaseOrderView>, { search?: string, accountManager?: Array<number>, deliveryDateFrom?: string, deliveryDateTo?: string, shipFrom?: Array<number>, shipTo?: Array<number>, status?: Array<PurchaseOrderStatus>, vendor?: Array<number>, onlyMyOrders?: boolean, query?: string, orderBy?: string, order?: Order, offset?: number, limit?: number }>('/v2/purchaseorders'),
    getPurchaseOrdersVendor: createGET<Array<PurchaseOrderView>, { search?: string, accountManager?: Array<number>, deliveryDateFrom?: string, deliveryDateTo?: string, shipFrom?: Array<number>, shipTo?: Array<number>, status?: Array<PurchaseOrderStatus>, query?: string, orderBy?: string, order?: Order, offset?: number, limit?: number }>('/v2/purchaseorders/vendor'),
    addPurchaseOrderMember: createPOST<void, { id: number, userId: number }, {}>('/v2/purchaseorders/{id}/follow/{userId}'),
    confirmPromiseDate: createPOST<void, { id: number }, {}>('/v2/purchaseorders/{id}/confirmPromiseDate'),
    removePurchaseOrderMember: createPOST<void, { id: number, userId: number }, {}>('/v2/purchaseorders/{id}/unfollow/{userId}'),
    updatePromiseDate: createPOST<void, { id: number }, UpdatePromiseDateData>('/v2/purchaseorders/{id}/promiseDate/'),

    getExternalPurchaseOrderChat: createGET<ChatUnreadView, { id: number }>('/v2/purchaseorders/{id}/chat/external'),
    getInternalPurchaseOrderChat: createGET<ChatUnreadView, { id: number }>('/v2/purchaseorders/{id}/chat/internal'),
    getPurchaseOrderChatId: createGET<ChatView, { id: number }>('/v2/purchaseorders/{id}/chat/id'),

    getPurchaseOrderDocuments: createGET<Array<DocumentView>, { id: number, includeOnbaseDocs?: boolean }>('/v2/purchaseorders/{id}/documents'),
    createMultiplePurchaseOrderDocuments: createPOST<null, {}, CreateMultiplePurchaseOrderDocumentsRequest>('/v2/purchaseorders/documents'),
    createPurchaseOrderDocument: createPOST<Document, { id: number }, CreatePurchaseOrderDocumentRequest>('/v2/purchaseorders/{id}/documents'),
    deletePurchaseOrderDocument: createDELETE<Document, { id: number }>('/v2/purchaseorders/documents/{id}'),

    getPlantPurchaseOrder: createGET<PurchaseOrderPlantView, { id: number }>('/v2/plants/purchaseorders/{id}'),
    getPlantPurchaseOrderItems: createGET<Array<PurchaseOrderItemPlantView>, { id: number }>('/v2/plants/purchaseorders/{id}/items'),
    getPlantPurchaseOrders: createGET<Array<PurchaseOrderPlantView>, { search?: string, locations?: Array<number>, status?: Array<PurchaseOrderStatus>, query?: string, orderBy?: string, order?: Order, offset?: number, limit?: number }>('/v2/plants/purchaseorders'),
    addPlantPurchaseOrderMember: createPOST<void, { id: number, userId: number }, {}>('/v2/plants/purchaseorders/{id}/follow/{userId}'),
    removePlantPurchaseOrderMember: createPOST<void, { id: number, userId: number }, {}>('/v2/plants/purchaseorders/{id}/unfollow/{userId}'),

    getQuoteAttachments: createGET<Array<Attachment>, { id: number }>('/v2/quotes/{id}/attachments'),
    updateQuoteAttachments: createPOST<Array<Attachment>, { id: number }, Array<number>>('/v2/quotes/{id}/attachments'),

    getExternalQuoteChat: createGET<ChatUnreadView, { id: number }>('/v2/quote/{id}/chat/external'),
    getInternalQuoteChat: createGET<ChatUnreadView, { id: number }>('/v2/quote/{id}/chat/internal'),
    getQuoteChatId: createGET<ChatView, { id: number }>('/v2/quote/{id}/chat/id'),

    getQuote: createGET<QuoteView, { id: number }>('/v2/quotes/{id}'),
    getQuotes: createGET<Array<QuoteView>, { query?: string, search?: string, status?: Array<QuoteStatus>, offer?: Array<number>, customer?: Array<number>, shipTo?: Array<number>, buyer?: Array<number>, accountManager?: Array<number>, valueFrom?: string, valueTo?: string, validityDateFrom?: string, validityDateTo?: string, orderCreationDateFrom?: string, orderCreationDateTo?: string, createDateFrom?: string, createDateTo?: string, product?: Array<number>, productGroup?: Array<number>, vendor?: Array<number>, port?: Array<number>, incoterm?: Array<string>, withChangesOnly?: boolean, onlyMy?: boolean, onlyNotLinkedToOffer?: boolean, onlyNotLinkedToRFQ?: boolean, onlyArchivedOrDeliveredOrExpired?: boolean, withoutArchivedOrDelivered?: boolean, withExpired?: boolean, orderBy?: string, order?: Order, offset?: number, limit?: number }>('/v2/quotes'),
    getQuotesByCustomerSummary: createGET<Array<QuotesByCustomerSummary>, { search?: string, offset?: number, limit?: number, status?: Array<QuoteStatus>, valueFrom?: string, valueTo?: string, validityDateFrom?: string, validityDateTo?: string, offer?: Array<number>, customer?: Array<number>, shipTo?: Array<number>, buyer?: Array<number>, product?: Array<number>, productGroup?: Array<number>, vendor?: Array<number>, port?: Array<number>, accountManager?: Array<number>, incoterm?: Array<string>, withChangesOnly?: boolean, onlyMy?: boolean, onlyArchivedOrDeliveredOrExpired?: boolean, withoutArchivedOrDelivered?: boolean, withExpired?: boolean }>('/v2/quotes/byCustomer'),
    getQuotesByOfferSummary: createGET<Array<QuotesByOfferSummary>, { search?: string, offset?: number, limit?: number, status?: Array<QuoteStatus>, valueFrom?: string, valueTo?: string, validityDateFrom?: string, validityDateTo?: string, offer?: Array<number>, customer?: Array<number>, shipTo?: Array<number>, buyer?: Array<number>, product?: Array<number>, productGroup?: Array<number>, vendor?: Array<number>, port?: Array<number>, accountManager?: Array<number>, incoterm?: Array<string>, withChangesOnly?: boolean, onlyMy?: boolean, onlyArchivedOrDeliveredOrExpired?: boolean, withoutArchivedOrDelivered?: boolean, withExpired?: boolean }>('/v2/quotes/byOffer'),
    getQuotesByShipToSummary: createGET<Array<QuotesByShipToSummary>, { search?: string, offset?: number, limit?: number, status?: Array<QuoteStatus>, valueFrom?: string, valueTo?: string, validityDateFrom?: string, validityDateTo?: string, offer?: Array<number>, customer?: Array<number>, shipTo?: Array<number>, buyer?: Array<number>, product?: Array<number>, productGroup?: Array<number>, vendor?: Array<number>, port?: Array<number>, accountManager?: Array<number>, incoterm?: Array<string>, withChangesOnly?: boolean, onlyMy?: boolean, onlyArchivedOrDeliveredOrExpired?: boolean, withoutArchivedOrDelivered?: boolean, withExpired?: boolean }>('/v2/quotes/byShipTo'),
    getQuotesByStatusSummary: createGET<Array<QuotesByStatusSummary>, { search?: string, status?: Array<QuoteStatus>, offer?: Array<number>, customer?: Array<number>, shipTo?: Array<number>, buyer?: Array<number>, valueFrom?: string, valueTo?: string, validityDateFrom?: string, validityDateTo?: string, product?: Array<number>, productGroup?: Array<number>, vendor?: Array<number>, port?: Array<number>, accountManager?: Array<number>, incoterm?: Array<string>, withChangesOnly?: boolean, onlyMy?: boolean, onlyArchivedOrDeliveredOrExpired?: boolean, withoutArchivedOrDelivered?: boolean, withExpired?: boolean }>('/v2/quotes/byStatusSummary'),
    addQuoteMember: createPOST<void, { id: number, userId: number }, {}>('/v2/quotes/{id}/follow/{userId}'),
    confirmQuote: createPOST<SalesOrderView, { id: number }, ConfirmQuoteRequest>('/v2/quotes/{id}/confirm'),
    createQuote: createPOST<QuoteView, {}, CreateQuoteRequest>('/v2/quotes'),
    postQuoteReadMark: createPOST<void, { quoteId: number }, {}>('/v2/quotes/{quoteId}/read'),
    removeQuoteMember: createPOST<void, { id: number, userId: number }, {}>('/v2/quotes/{id}/unfollow/{userId}'),
    updateQuote: createPOST<QuoteView, { id: number }, QuoteUpdate>('/v2/quotes/{id}'),
    deleteQuote: createDELETE<null, { id: number }>('/v2/quotes/{id}'),

    createQuoteFeedback: createPOST<QuoteView, { id: number }, CreateQuoteFeedbackRequest>('/v2/quotes/{id}/feedback'),

    getQuoteProduct: createGET<QuoteProduct, { quoteId: number, id: number }>('/v2/quotes/{quoteId}/product/{id}'),
    getQuoteProducts: createGET<Array<QuoteProduct>, { id: number }>('/v2/quotes/{id}/products'),

    getRandomLengthLumber: createGET<Array<RandomLengthLumberView>, { search?: string, orderBy?: string, order?: Order, offset?: number, limit?: number }>('/v2/randomLengths/lumber'),
    getRandomLengthPanels: createGET<Array<RandomLengthPanelView>, { search?: string, orderBy?: string, order?: Order, offset?: number, limit?: number }>('/v2/randomLengths/panel'),
    updateRandomLengthLumber: createPOST<RandomLengthLumberView, { id: number }, UpdateRandomLengthLumberRequest>('/v2/randomLengths/lumber/{id}'),
    updateRandomLengthPanel: createPOST<RandomLengthPanelView, { id: number }, UpdateRandomLengthPanelRequest>('/v2/randomLengths/panel/{id}'),

    getRequestForQuoteAttachments: createGET<Array<Attachment>, { id: number }>('/v2/requestsForQuotes/{id}/attachments'),
    updateRequestForQuoteAttachments: createPOST<Array<Attachment>, { id: number }, Array<number>>('/v2/requestsForQuotes/{id}/attachments'),

    getExternalRequestForQuoteChat: createGET<ChatUnreadView, { id: number }>('/v2/requestsForQuotes/{id}/chat/external'),
    getInternalRequestForQuoteChat: createGET<ChatUnreadView, { id: number }>('/v2/requestsForQuotes/{id}/chat/internal'),
    getRequestForQuoteChatId: createGET<ChatView, { id: number }>('/v2/requestsForQuotes/{id}/chat/id'),

    getRequestForQuote: createGET<RequestForQuoteView, { id: number }>('/v2/requestsForQuotes/{id}'),
    getRequestsForQuotes: createGET<Array<RequestForQuoteView>, { query?: string, search?: string, status?: Array<RequestForQuoteStatus>, customer?: Array<number>, productCode?: Array<number>, valueFrom?: string, valueTo?: string, validityDateFrom?: string, validityDateTo?: string, quoteCreationDateFrom?: string, quoteCreationDateTo?: string, shipTo?: Array<number>, product?: Array<number>, productGroup?: Array<number>, port?: Array<number>, accountManager?: Array<number>, follower?: Array<number>, incoterm?: Array<string>, onlyMy?: boolean, withExpired?: boolean, onlyArchivedOrDeliveredOrExpired?: boolean, withoutArchivedOrDelivered?: boolean, orderBy?: string, order?: Order, offset?: number, limit?: number }>('/v2/requestsForQuotes'),
    addRequestForQuoteMember: createPOST<void, { id: number, userId: number }, {}>('/v2/requestsForQuotes/{id}/follow/{userId}'),
    createRequestForQuote: createPOST<RequestForQuoteView, {}, CreateRequestForQuoteRequest>('/v2/requestsForQuotes'),
    postRequestForQuoteReadMark: createPOST<void, { requestId: number }, {}>('/v2/requestsForQuotes/{requestId}/read'),
    removeRequestForQuoteMember: createPOST<void, { id: number, userId: number }, {}>('/v2/requestsForQuotes/{id}/unfollow/{userId}'),
    updateRequestForQuote: createPOST<RequestForQuoteView, { id: number }, RequestForQuoteUpdate>('/v2/requestsForQuotes/{id}'),
    deleteRequestForQuote: createDELETE<null, { id: number }>('/v2/requestsForQuotes/{id}'),

    getRequestForQuoteMatchingProductsForCompany: createGET<Array<RequestsForQuotesMatchedProduct>, { id: number, companyId: number }>('/v2/requestsForQuotes/{id}/matchingVendors/{companyId}/products'),
    getRequestForQuoteMatchingVendors: createGET<Array<RequestsForQuotesMatchingResult>, { id: number }>('/v2/requestsForQuotes/{id}/matchingVendors'),
    createOffersFromRequestForQuote: createPOST<Array<OfferView>, { id: number }, Array<CreateOfferFromRequestForQuoteRequest>>('/v2/requestsForQuotes/{id}/offers'),

    getRequestsForQuotesByShipToSummary: createGET<Array<RequestsForQuotesByShipToSummary>, { search?: string, offset?: number, limit?: number, status?: Array<RequestForQuoteStatus>, customer?: Array<number>, shipTo?: Array<number>, product?: Array<number>, productGroup?: Array<number>, valueFrom?: string, valueTo?: string, validityDateFrom?: string, validityDateTo?: string, port?: Array<number>, accountManager?: Array<number>, follower?: Array<number>, incoterm?: Array<string>, onlyMy?: boolean, withExpired?: boolean, onlyArchivedOrDeliveredOrExpired?: boolean, withoutArchivedOrDelivered?: boolean }>('/v2/requestsForQuotes/byShipTo'),
    getRequestsForQuotesByStatusSummary: createGET<Array<RequestsForQuotesByStatusSummary>, { search?: string, customer?: Array<number>, shipTo?: Array<number>, product?: Array<number>, productGroup?: Array<number>, valueFrom?: string, valueTo?: string, validityDateFrom?: string, validityDateTo?: string, port?: Array<number>, accountManager?: Array<number>, follower?: Array<number>, incoterm?: Array<string>, onlyMy?: boolean, withExpired?: boolean, onlyArchivedOrDeliveredOrExpired?: boolean, withoutArchivedOrDelivered?: boolean }>('/v2/requestsForQuotes/byStatus'),

    getSalesOrder: createGET<SalesOrderView, { id: number }>('/v2/salesorders/{id}'),
    getSalesOrderInvoices: createGET<Array<DeliveryInvoice>, { id: number }>('/v2/salesorders/{id}/invoices'),
    getSalesOrderItems: createGET<Array<DeliveryItem>, { id: number }>('/v2/salesorders/{id}/items'),
    getSalesOrders: createGET<Array<SalesOrderView>, { search?: string, locations?: Array<number>, customer?: Array<number>, status?: Array<DeliveryBuyerStatus>, accountManager?: Array<number>, deliveryDateFrom?: string, deliveryDateTo?: string, companyCreateDateFrom?: string, companyCreateDateTo?: string, onlyMyOrders?: boolean, query?: string, orderBy?: string, order?: Order, offset?: number, limit?: number }>('/v2/salesorders'),
    getSalesOrdersCustomer: createGET<Array<SalesOrderView>, { search?: string, locations?: Array<number>, status?: Array<DeliveryBuyerStatus>, accountManager?: Array<number>, deliveryDateFrom?: string, deliveryDateTo?: string, query?: string, orderBy?: string, order?: Order, offset?: number, limit?: number }>('/v2/salesorders/customer'),
    addSalesOrderMember: createPOST<void, { id: number, userId: number }, {}>('/v2/salesorders/{id}/follow/{userId}'),
    removeSalesOrderMember: createPOST<void, { id: number, userId: number }, {}>('/v2/salesorders/{id}/unfollow/{userId}'),

    getExternalSalesOrderChat: createGET<ChatUnreadView, { id: number }>('/v2/salesorders/{id}/chat/external'),
    getInternalSalesOrderChat: createGET<ChatUnreadView, { id: number }>('/v2/salesorders/{id}/chat/internal'),
    getSalesOrderChatId: createGET<ChatView, { id: number }>('/v2/salesorders/{id}/chat/id'),

    getSalesOrderDocuments: createGET<Array<DocumentView>, { id: number, includeOnbaseDocs?: boolean }>('/v2/salesorders/{id}/documents'),
    createMultipleSalesOrderDocuments: createPOST<null, {}, CreateMultipleDocumentsRequest>('/v2/salesorders/documents'),
    createSalesOrderDocument: createPOST<Document, { id: number }, CreateDocumentRequest>('/v2/salesorders/{id}/documents'),
    editOrderDocument: createPUT<Document, {}, UpdateDocumentRequest>('/v2/orders/documents'),
    deleteSalesOrderDocument: createDELETE<Document, { id: number }>('/v2/salesorders/documents/{id}'),

    getActiveUnitTypesForShipVia: createGET<ShipViaUnitTypeViewModel, { shipVia: string }>('/shipvia/{shipVia}/units'),
    getAllActiveShipViaUnitTypes: createGET<Array<ShipViaUnitTypeViewModel>, {}>('/shipvia/units'),
    insertShipViaUnitType: createPOST<ShipViaUnitType, {}, InsertShipViaUnitTypeRequest>('/shipvia/unit'),
    updateShipViaUnitType: createPUT<ShipViaUnitType, {}, UpdateShipViaUnitTypeRequest>('/shipvia/unit'),

    getSyndigoProduct: createGET<SyndigoProductViewModel, { productCode: string }>('/syndigo/product/{productCode}'),
    getSyndigoProducts: createGET<Array<SyndigoProductViewModel>, { id?: Array<number>, productCode?: Array<string> }>('/syndigo/products'),
    updateSyndigoProducts: createPOST<Array<SyndigoProductViewModel>, { productCode: Array<string> }, {}>('/syndigo/products'),

    viewUfpDocument: createGET<Blob, { document: string, token: string }>('/v2/ufp-documents/view'),

    importVendor: createPOST<VendorImportResultView, { vendorCode: string }, {}>('/vendors/import/{vendorCode}'),

    triggerJob: createPOST<void, {}, JobTriggerData>('/internal/trigger-job'),

    getJobInfo: createGET<Array<ScheduledJobInfo>, {}>('/internal/scheduler/info'),
//generated endpoints end
    urlAttachmentsZip: (ids: number[] | string[], name?: string) =>
        `${config.base}/attachments/zip/${encodeURIComponent(name || "")}?${toRequestParams({ ids })}`,
    urlAttachmentOpen: (id: number | string, name?: string) =>
        `${config.base}/attachments/${id}/data/${encodeURIComponent(name || "")}`,
    urlAttachmentThumbnail: (id: number | string) =>
        `${config.base}/attachments/${id}/thumbnail`,
    urlAttachmentDownload: (id: number | string, name?: string) =>
        `${config.base}/attachments/${id}/data/${encodeURIComponent(name || "")}`,

    urlImageThumbnail: (id: number | string) =>
        `${config.base}/images/${id}/thumbnail`,
    urlImageThumbnailLarge: (id: number | string) =>
        `${config.base}/images/${id}/thumbnailLarge`,
};

export default exports;
try {
    module.exports = exports;
} catch (ignored) {
}
//generated types start
export interface GroupPermissionArray {
}

export interface CreateAttachmentData {
	name: string,
	size: number,
	restriction: AttachmentRestriction,
	ref?: AttachmentRef | null,
	refId?: number | null,
	active: boolean,
}

export enum AttachmentRestriction {
	PUBLIC = 'PUBLIC',
	COMPANY = 'COMPANY',
	REFERENCE = 'REFERENCE',
}

export enum AttachmentRef {
	LANDING_PAGE = 'LANDING_PAGE',
	USER_ACCOUNT = 'USER_ACCOUNT',
	OFFER = 'OFFER',
	QUOTE = 'QUOTE',
}

export interface AddChatParticipantRequest {
	userAccountId: number,
}

export interface CreateDirectChatRequest {
	title: string,
	internal: boolean,
	type: ChatType,
	participants: Array<number>,
}

export enum ChatType {
	DIRECT = 'DIRECT',
	SALES_ORDER = 'SALES_ORDER',
	PURCHASE_ORDER = 'PURCHASE_ORDER',
	OFFER = 'OFFER',
	QUOTE = 'QUOTE',
	REQUEST_FOR_QUOTE = 'REQUEST_FOR_QUOTE',
}

export interface NewMessage {
	text: string,
	attachments: Array<Attachment>,
	mentionedUserIds: Array<number>,
}

export interface updateClassBody {
	updateLevel?: NotificationLevelEnum | null,
}

export interface CompanySetCodesRequest {
	code: string,
	locationsCodes: MapLongToString,
}

export type MapLongToString = {
	[key in number]: string
}

export interface CompanyUpdateRequest {
	active: boolean,
	legalName: string,
	code?: string | null,
	accountManagerUserId?: number | null,
	addressCountry?: string | null,
	addressRegion?: string | null,
	addressCity?: string | null,
	addressStreet?: string | null,
	addressStreet2?: string | null,
	addressZip?: string | null,
	defaultMarginAsFraction?: number | null,
	additionalResponsibles?: Array<number> | null,
	requireOrderApproval: boolean,
	isInternal: boolean,
	isDomestic: boolean,
}

export interface LocationUpdateRequest {
	companyId: number,
	name?: string | null,
	active: boolean,
	code?: string | null,
	addressCountry?: string | null,
	addressRegion?: string | null,
	addressCity?: string | null,
	addressStreet?: string | null,
	addressStreet2?: string | null,
	addressZip?: string | null,
	accountManagerUserId?: number | null,
	defaultMarginAsFraction?: number | null,
	billToCode?: string | null,
	billToName?: string | null,
	billToAddressStreet1?: string | null,
	billToAddressStreet2?: string | null,
	billToAddressCity?: string | null,
	billToAddressRegion?: string | null,
	billToAddressZip?: string | null,
	billToAddressCountry?: string | null,
	paymentTermsDescription?: string | null,
	portId?: number | null,
	incoterm?: string | null,
	uomDefault?: LocationUom | null,
	hidden: boolean,
	locationCode?: string | null,
	additionalResponsibles?: Array<number> | null,
}

export enum LocationUom {
	PCS = 'PCS',
	CBM = 'CBM',
	MBF = 'MBF',
}

export interface UnreadNotificationsCount {
	unreadCount: number,
}

export interface ExportSeaFreightCostsData {
	data?: string | null,
}

export interface SeaFreightCostUpdateRequest {
	portOfLoadingId: number,
	portOfDischargeId: number,
	pricePerContainer: number,
	currencyPrice: string,
	dthcPerContainer: number,
	currencyDTHC: string,
	gassingPerContainer: number,
	currencyGassing: string,
	additionalCostsPerContainer: number,
	currencyAdditionalCostsPerContainer: string,
	additionalCostsPerShipment: number,
	currencyAdditionalCostsPerShipment: string,
	dateExpired?: string | null,
	forwarder?: string | null,
	active: boolean,
	transitDurationDays?: number | null,
	notes?: string | null,
	destinationLoadingId?: number | null,
	destinationDischargeId?: number | null,
	incotermLoading?: SeaFreightCostIncoterm | null,
	incotermDischarge?: SeaFreightCostIncoterm | null,
	category?: SeaFreightCostCategory | null,
	addons?: Array<number> | null,
}

export enum SeaFreightCostIncoterm {
	FCA = 'FCA',
	FOB = 'FOB',
	CFR = 'CFR',
	CIF = 'CIF',
	DAP = 'DAP',
	DDP = 'DDP',
	FCA_COMBO = 'FCA_COMBO',
	DDP_COMBO = 'DDP_COMBO',
	DAP_COMBO = 'DAP_COMBO',
}

export enum SeaFreightCostCategory {
	IMPORTS = 'IMPORTS',
	F2F = 'F2F',
	EXPORT = 'EXPORT',
	EXPORT_MANUFACTURING = 'EXPORT_MANUFACTURING',
}

export interface SeaFreightCostView {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	portOfLoadingId: number,
	portOfDischargeId: number,
	pricePerContainer: number,
	currencyPrice: string,
	dthcPerContainer: number,
	currencyDthc: string,
	gassingPerContainer: number,
	currencyGassing: string,
	additionalCostsPerContainer: number,
	currencyAdditionalCostsPerContainer: string,
	additionalCostsPerShipment: number,
	currencyAdditionalCostsPerShipment: string,
	dateExpired?: string | null,
	forwarder?: string | null,
	active: boolean,
	transitDurationDays?: number | null,
	notes?: string | null,
	updatedByUserAccountId?: number | null,
	destinationLoadingId?: number | null,
	destinationDischargeId?: number | null,
	incotermLoading?: SeaFreightCostIncoterm | null,
	incotermDischarge?: SeaFreightCostIncoterm | null,
	category?: SeaFreightCostCategory | null,
	portOfLoadingCode: string,
	portOfLoadingName: string,
	portOfDischargeCode: string,
	portOfDischargeName: string,
	destinationLoadingName: string,
	destinationDischargeName: string,
	addons?: Array<number> | null,
}

export interface AcceptVideoCallRequest {
	userAccountId: number,
	chatMessageId: number,
}

export interface CallUserRequest {
	userAccountId: number,
	chatId: number,
}

export interface DeclineVideoCallRequest {
	userAccountId: number,
	chatMessageId: number,
}

export interface EndCallRequest {
	userAccountId: number,
}

export interface JoinRoomRequest {
	name: string,
	userIdentity: string,
}

export interface JoinRoomResponse {
	token: string,
}

export interface LoginData {
	email: string,
	password: string,
}

export interface LoginResponse {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	name: string,
	surname: string,
	email: string,
	language: string,
	active: boolean,
	companyId?: number | null,
	token: string,
	photoAttachmentId?: number | null,
	sr?: number | null,
	permissions: Array<GroupPermission>,
	isCompanyInternal: boolean,
	userGroupId?: number | null,
	locations: Array<number>,
	amInCompanies: MapLongToBoolean,
	amInLocations: MapLongToBoolean,
	needsPasswordReset: boolean,
	showBellNotifications: boolean,
}

export type MapLongToBoolean = {
	[key in number]: boolean
}

export interface ResetPasswordRequest {
	email: string,
}

export interface SetNewPasswordRequest {
	password: string,
	resetToken: string,
}

export interface UpdatePasswordBody {
	currentPassword: string,
	newPassword: string,
}

export interface CreateUserRequest {
	name: string,
	surname: string,
	email: string,
	language?: string | null,
	companyId: number,
	locations?: Array<number> | null,
	userGroupId?: number | null,
	sendInvitation: boolean,
	company?: CompanyCreateRequest | null,
	location?: CreateUserLocationRequest | null,
	useExistingCompany: boolean,
}

export interface CompanyCreateRequest {
	legalName: string,
	code?: string | null,
	accountManagerUserId?: number | null,
	addressStreet?: string | null,
	addressStreet2?: string | null,
	addressZip?: string | null,
	addressCity?: string | null,
	addressRegion?: string | null,
	addressCountry?: string | null,
	defaultMarginAsFraction?: number | null,
	type: CompanyType,
	additionalResponsibles?: Array<number> | null,
}

export enum CompanyType {
	BUYER = 'BUYER',
	SELLER = 'SELLER',
}

export interface CreateUserLocationRequest {
	name?: string | null,
	code?: string | null,
	addressCountry?: string | null,
	addressRegion?: string | null,
	addressCity?: string | null,
	addressStreet?: string | null,
	addressStreet2?: string | null,
	addressZip?: string | null,
	type?: LocationType | null,
	accountManagerUserId?: number | null,
	defaultMarginAsFraction?: number | null,
	billToCode?: string | null,
	billToName?: string | null,
	billToAddressStreet1?: string | null,
	billToAddressStreet2?: string | null,
	billToAddressCity?: string | null,
	billToAddressRegion?: string | null,
	billToAddressZip?: string | null,
	billToAddressCountry?: string | null,
	paymentTermsDescription?: string | null,
	portId?: number | null,
	incoterm?: string | null,
	uomDefault?: LocationUom | null,
	hidden: boolean,
	additionalResponsibles?: Array<number> | null,
	useCompanyAddress: boolean,
	useLocationAddress: boolean,
}

export interface ResendConfirmEmailRequest {
	email: string,
}

export interface SignupData {
	name: string,
	surname: string,
	password: string,
	email: string,
	language?: string | null,
}

export interface UserAccountUpdate {
	id: number,
	name: string,
	surname: string,
	password: string,
	email: string,
	language: string,
	active: boolean,
	userGroupId?: number | null,
	companyId?: number | null,
	photoAttachmentId?: number | null,
	locations?: Array<number> | null,
	sr?: number | null,
	showBellNotifications?: boolean | null,
	registrationStatus?: Registration | null,
	defaultNotificationLevel?: NotificationLevelEnum | null,
}

export interface UnreadMessagesCount {
	unreadCount: number,
	unreadCountInternal: number,
	unreadCountExternal: number,
}

export enum NotificationLevelEnum {
	ALL = 'ALL',
	ONLY_MENTIONS = 'ONLY_MENTIONS',
	NONE = 'NONE',
}

export enum Registration {
	NOT_SENT = 'NOT_SENT',
	EMAIL_SENT = 'EMAIL_SENT',
	REGISTERED = 'REGISTERED',
}

export interface Attachment {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	userAccountId: number,
	name: string,
	size: number,
	restriction: AttachmentRestriction,
	status: AttachmentStatus,
	hasThumbnail: boolean,
	active: boolean,
}

export enum AttachmentStatus {
	UPLOADING = 'UPLOADING',
	PROCESSING = 'PROCESSING',
	READY = 'READY',
}

export interface Chat {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	emailNotifications: boolean,
	archived: boolean,
	internal: boolean,
	title: string,
	type: ChatType,
}

export interface ChatMessage {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	chatId: number,
	userAccountId?: number | null,
	text: string,
	type: ChatMessageType,
	mentionedUserIds: Array<number>,
}

export enum ChatMessageType {
	TEXT = 'TEXT',
	CALL_INVITATION = 'CALL_INVITATION',
	SYSTEM_MESSAGE = 'SYSTEM_MESSAGE',
	OFFER_FEEDBACK = 'OFFER_FEEDBACK',
	QUOTE_FEEDBACK = 'QUOTE_FEEDBACK',
	FIELD_CHANGE = 'FIELD_CHANGE',
}

export interface ChatMessageReadMark {
	id: number,
	dateCreated: string,
	userAccountId: number,
	chatMessageId: number,
}

export interface Company {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	legalName: string,
	address?: string | null,
	type: CompanyType,
	addressCountry?: string | null,
	addressRegion?: string | null,
	addressCity?: string | null,
	addressStreet?: string | null,
	addressStreet2?: string | null,
	addressZip?: string | null,
	accountManagerUserId?: number | null,
	code?: string | null,
	paymentTermsCode?: string | null,
	paymentTermsDescription?: string | null,
	defaultMarginAsFraction?: number | null,
	status?: CompanyStatus | null,
	importMd5?: string | null,
	active: boolean,
	updatedByUserAccountId?: number | null,
	requireOrderApproval: boolean,
	isInternal: boolean,
	isDomestic: boolean,
	dateConnectedMsr?: string | null,
}

export enum CompanyStatus {
	LEAD = 'LEAD',
	MSR_CONFIRMED = 'MSR_CONFIRMED',
}

export enum DeliveryBuyerStatus {
	INQUIRY = 'INQUIRY',
	RESERVED = 'RESERVED',
	COMPLETE = 'COMPLETE',
	VOID = 'VOID',
}

export interface DeliveryInvoice {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	deliveryId: number,
	invoiceNumber: number,
	currency: string,
	invoiceAmount: number,
	remainingAmount: number,
	paymentCount: number,
	paid: boolean,
	dateDue?: string | null,
	dateInvoiced?: string | null,
}

export interface DeliveryItem {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	deliveryItemNumber: number,
	vol?: number | null,
	volUom?: string | null,
	metricVol?: number | null,
	deliveryId: number,
	productType?: string | null,
	productCode?: string | null,
	description?: string | null,
	lengthCode?: string | null,
	pkgTotal?: number | null,
	price?: number | null,
	priceUnit?: string | null,
	extension?: number | null,
	piecesPerPackage?: number | null,
	hasSyndigo: boolean,
	productId?: number | null,
}

export interface Document {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	attachmentId: number,
	type: DocumentType,
	number: number,
	active: boolean,
}

export enum DocumentType {
	C = 'C',
	P = 'P',
	PO = 'PO',
	I = 'I',
	PL = 'PL',
	CN = 'CN',
	DIB = 'DIB',
	COO_DRAFT = 'COO_DRAFT',
	PHYTO_DRAFT = 'PHYTO_DRAFT',
	SAWMILL_INVOICE = 'SAWMILL_INVOICE',
	BL_DRAFT = 'BL_DRAFT',
	BL_ORIGINAL = 'BL_ORIGINAL',
	COO_ORIGINAL = 'COO_ORIGINAL',
	INSURANCE_POLICY = 'INSURANCE_POLICY',
	SAWMILL_PACKING_LIST = 'SAWMILL_PACKING_LIST',
	SWB_DRAFT = 'SWB_DRAFT',
	SWB_ORIGINAL = 'SWB_ORIGINAL',
	PHYTO_ORIGINAL = 'PHYTO_ORIGINAL',
	SAWMILL_ORDER_CONFIRMATION = 'SAWMILL_ORDER_CONFIRMATION',
	HEAT_TREATMENT_RECORDS = 'HEAT_TREATMENT_RECORDS',
	PAYMENT_CONFIRMATION = 'PAYMENT_CONFIRMATION',
	PAYMENT_CONFIRMATION_PREPAYMENT = 'PAYMENT_CONFIRMATION_PREPAYMENT',
	SHIPPERS_LETTER_OF_INSTRUCTIONS = 'SHIPPERS_LETTER_OF_INSTRUCTIONS',
	COMMISSION_INVOICE_TO_SELLER = 'COMMISSION_INVOICE_TO_SELLER',
	PACKAGING_DECLARATION = 'PACKAGING_DECLARATION',
	OTHER = 'OTHER',
	SHIPPING_SUPPORTING_DOCS = 'SHIPPING_SUPPORTING_DOCS',
	DRAFT = 'DRAFT',
}

export interface Email {
	id: number,
	dateCreated: string,
	userAccountId?: number | null,
	to: string,
	from: string,
	subject: string,
	body: string,
	trigger: string,
	sentSuccessfully: boolean,
}

export enum GroupPermission {
	NOOP = 'NOOP',
	CAN_BE_SALES_CONTACT_PERSON = 'CAN_BE_SALES_CONTACT_PERSON',
	CAN_BE_ADMIN = 'CAN_BE_ADMIN',
	CAN_BE_CUSTOMER = 'CAN_BE_CUSTOMER',
	CAN_BE_VENDOR = 'CAN_BE_VENDOR',
	CAN_BE_SELLER = 'CAN_BE_SELLER',
	CAN_BE_BUYER = 'CAN_BE_BUYER',
	CAN_BE_LOGISTICS = 'CAN_BE_LOGISTICS',
}

export interface LandingPage {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	name: string,
	title: string,
	active: boolean,
	selectedImage: number,
}

export interface Location {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	companyId: number,
	active: boolean,
	name?: string | null,
	code?: string | null,
	addressCountry?: string | null,
	addressRegion?: string | null,
	addressCity?: string | null,
	addressStreet?: string | null,
	addressStreet2?: string | null,
	addressZip?: string | null,
	type?: LocationType | null,
	accountManagerUserId?: number | null,
	defaultMarginAsFraction?: number | null,
	billToCode?: string | null,
	billToName?: string | null,
	billToAddressStreet1?: string | null,
	billToAddressStreet2?: string | null,
	billToAddressCity?: string | null,
	billToAddressRegion?: string | null,
	billToAddressZip?: string | null,
	billToAddressCountry?: string | null,
	paymentTermsCode?: string | null,
	paymentTermsDescription?: string | null,
	portId?: number | null,
	hidden: boolean,
	uomDefault?: LocationUom | null,
	importMd5?: string | null,
	incoterm?: string | null,
	status?: LocationStatus | null,
	updatedByUserAccountId?: number | null,
	locationCode?: string | null,
}

export enum LocationStatus {
	LEAD = 'LEAD',
	MSR_CONFIRMED = 'MSR_CONFIRMED',
}

export enum LocationType {
	SHIP_TO = 'SHIP_TO',
	SHIP_FROM = 'SHIP_FROM',
}

export interface Notification {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	userAccountId: number,
	data: NotificationData,
	read: boolean,
}

export interface NotificationData {
	type: NotificationType,
	refs: MapNotificationRefToStringArray,
}

export type MapNotificationRefToStringArray = {
	[key in NotificationRef]: Array<string>
}

export enum NotificationRef {
	CHAT_MESSAGE = 'CHAT_MESSAGE',
	CHAT = 'CHAT',
	REQUEST = 'REQUEST',
	QUOTE = 'QUOTE',
	OFFER = 'OFFER',
	REQUEST_BUYER = 'REQUEST_BUYER',
	REQUEST_SELLER = 'REQUEST_SELLER',
	ORDER_BUYER = 'ORDER_BUYER',
	ORDER_SELLER = 'ORDER_SELLER',
	DELIVERY_BUYER = 'DELIVERY_BUYER',
	DELIVERY_SELLER = 'DELIVERY_SELLER',
	COMPANY = 'COMPANY',
	MARKETPLACE_USER = 'MARKETPLACE_USER',
	PRODUCT_SELLER = 'PRODUCT_SELLER',
	USER = 'USER',
	TASK = 'TASK',
	PURCHASE_ORDER = 'PURCHASE_ORDER',
}

export enum NotificationType {
	NEW_CHAT_MESSAGE = 'NEW_CHAT_MESSAGE',
	TASK_ASSIGNED = 'TASK_ASSIGNED',
	USER_REGISTERED = 'USER_REGISTERED',
	QUOTE_FEEDBACK = 'QUOTE_FEEDBACK',
	OFFER_FEEDBACK = 'OFFER_FEEDBACK',
	SYSTEM_MESSAGE = 'SYSTEM_MESSAGE',
	FIELD_CHANGE = 'FIELD_CHANGE',
}

export interface Port {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	name: string,
	code?: string | null,
	city: string,
	country: string,
	types: Array<PortType>,
	active: boolean,
	type: PortType,
}

export enum PortType {
	PORT_OF_LOADING = 'PORT_OF_LOADING',
	PORT_OF_DISCHARGE = 'PORT_OF_DISCHARGE',
	PORT = 'PORT',
	CY = 'CY',
	RAMP = 'RAMP',
	DOOR = 'DOOR',
}

export interface SeaFreightCost {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	portOfLoadingId: number,
	portOfDischargeId: number,
	pricePerContainer: number,
	currencyPrice: string,
	dthcPerContainer: number,
	currencyDthc: string,
	gassingPerContainer: number,
	currencyGassing: string,
	additionalCostsPerContainer: number,
	currencyAdditionalCostsPerContainer: string,
	additionalCostsPerShipment: number,
	currencyAdditionalCostsPerShipment: string,
	dateExpired?: string | null,
	forwarder?: string | null,
	active: boolean,
	transitDurationDays?: number | null,
	notes?: string | null,
	updatedByUserAccountId?: number | null,
	destinationLoadingId?: number | null,
	destinationDischargeId?: number | null,
	incotermLoading?: SeaFreightCostIncoterm | null,
	incotermDischarge?: SeaFreightCostIncoterm | null,
	category?: SeaFreightCostCategory | null,
}

export interface Translation {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	context: string,
	en: string,
	de: string,
	suggest: boolean,
	aliases: Array<string>,
	ref?: TranslationRef | null,
}

export interface TranslationRef {
	dateUpdated: string,
	id: number,
	table: string,
}

export interface UserGroup {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	name: string,
	parentId?: number | null,
	active: boolean,
}

export interface UserEmailSettingsView {
	chatEmailOptOut?: boolean | null,
	orderNewEmailOptOut?: boolean | null,
	orderUpdateEmailOptOut?: boolean | null,
	quoteNewEmailOptOut?: boolean | null,
	quoteUpdateEmailOptOut?: boolean | null,
	promiseDateEmailOptOut?: boolean | null,
}

export interface AttachmentRefsView {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	userAccountId: number,
	name: string,
	size: number,
	restriction: AttachmentRestriction,
	status: AttachmentStatus,
	hasThumbnail: boolean,
	active: boolean,
	refs: AttachmentRefs,
}

export interface AttachmentRefs {
	companies: Array<number>,
	chatMessages: Array<number>,
	landingPages: Array<number>,
	documents: Array<number>,
	users: Array<number>,
	quotes: Array<number>,
	offers: Array<number>,
	requestForQuotes: Array<number>,
}

export interface CompanyUsersView {
	id: number,
	active: boolean,
	code?: string | null,
	dateCreated: string,
	dateUpdated: string,
	legalName: string,
	address?: string | null,
	type: CompanyType,
	addressCountry?: string | null,
	addressRegion?: string | null,
	addressCity?: string | null,
	addressStreet?: string | null,
	addressStreet2?: string | null,
	addressZip?: string | null,
	accountManagerUserId?: number | null,
	paymentTermsCode?: string | null,
	paymentTermsDescription?: string | null,
	defaultMarginAsFraction?: number | null,
	status?: CompanyStatus | null,
	dateConnectedMsr?: string | null,
	users: Array<number>,
	additionalResponsibles: Array<number>,
	locations: Array<number>,
	locationAccountManagers: Array<number>,
	requireOrderApproval: boolean,
	isInternal: boolean,
	isDomestic: boolean,
}

export enum Order {
	ASC = 'ASC',
	DESC = 'DESC',
}

export interface LocationView {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	companyId: number,
	active: boolean,
	name?: string | null,
	code?: string | null,
	addressCountry?: string | null,
	addressRegion?: string | null,
	addressCity?: string | null,
	addressStreet?: string | null,
	addressZip?: string | null,
	type?: LocationType | null,
	accountManagerUserId?: number | null,
	defaultMarginAsFraction?: number | null,
	addressStreet2?: string | null,
	billToCode?: string | null,
	billToName?: string | null,
	billToAddressStreet1?: string | null,
	billToAddressStreet2?: string | null,
	billToAddressCity?: string | null,
	billToAddressRegion?: string | null,
	billToAddressZip?: string | null,
	billToAddressCountry?: string | null,
	paymentTermsDescription?: string | null,
	portId?: number | null,
	hidden: boolean,
	incoterm?: string | null,
	status?: LocationStatus | null,
	paymentTermsCode?: string | null,
	locationCode?: string | null,
	accountManagerSurname?: string | null,
	accountManagerName?: string | null,
	customerCode?: string | null,
	portName?: string | null,
	users: Array<number>,
	additionalResponsibles: Array<number>,
}

export interface UpsertPortRequest {
	country: string,
	city: string,
	code?: string | null,
	active: boolean,
	type: PortType,
}

export interface UserAccountPublicView {
	id: number,
	name: string,
	surname: string,
	email: string,
	language: string,
	companyLegalName?: string | null,
	companyId?: number | null,
	photoAttachmentId?: number | null,
	active: boolean,
	registrationStatus: Registration,
}

export interface UserAccountView {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	name: string,
	surname: string,
	email: string,
	language: string,
	active: boolean,
	companyId?: number | null,
	photoAttachmentId?: number | null,
	sr?: number | null,
	lastActivityDate?: string | null,
	dateRegistered?: string | null,
	defaultNotificationLevel: NotificationLevelEnum,
	userGroupId?: number | null,
	isCompanyInternal: boolean,
	locations: Array<number>,
	showBellNotifications: boolean,
	registrationStatus: Registration,
}

export interface UserGroupPermissionsView {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	name: string,
	parentId?: number | null,
	active: boolean,
	permissions: Array<GroupPermission>,
	users: Array<number>,
}

export interface ChatMessageView {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	chatId: number,
	userAccountId?: number | null,
	text: string,
	type: ChatMessageType,
	mentionedUserIds: Array<number>,
	userName: string,
	userSurname: string,
	attachments: Array<number>,
}

export interface ChatUnreadView extends ChatView {
	unread: number,
}

export interface ChatView {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	emailNotifications: boolean,
	archived: boolean,
	internal: boolean,
	title: string,
	type: ChatType,
	totalMessagesCount: number,
	refs: ChatRefs,
	participants: Array<number>,
	lastMessage?: ChatMessageView | null,
}

export interface ChatRefs {
	companies: Array<number>,
	buyerDeliveries: Array<number>,
	purchaseOrders: Array<number>,
	offers: Array<number>,
	quotes: Array<number>,
}

export interface AddOnCostUpdate {
	type: AddOnCostType,
	category: AddOnCostCategory,
	per: AddOnCostPer,
	price: number,
	currency: string,
	active: boolean,
	description: string,
}

export enum AddOnCostType {
	IMPORTS = 'IMPORTS',
	F2F = 'F2F',
	EXPORT = 'EXPORT',
	EXPORT_MANUFACTURING = 'EXPORT_MANUFACTURING',
}

export enum AddOnCostCategory {
	_2420_ACCRUALS = '_2420_ACCRUALS',
	_032_ACCRUAL_LOGISTICS_FEES = '_032_ACCRUAL_LOGISTICS_FEES',
	DUTIES = 'DUTIES',
	ISF = 'ISF',
	BULK_TERMINAL_COSTS = 'BULK_TERMINAL_COSTS',
	OCEAN_FREIGHT = 'OCEAN_FREIGHT',
	DRAY_UNSTUFF_WAREHOUSING = 'DRAY_UNSTUFF_WAREHOUSING',
	TRUCKING_FROM_WH_TO_PLANT = 'TRUCKING_FROM_WH_TO_PLANT',
	MISC_PLATFORM_MAINT = 'MISC_PLATFORM_MAINT',
	PHYTO = 'PHYTO',
	INBOUND_TRUCKING_FROM_MILL_TO_WH = 'INBOUND_TRUCKING_FROM_MILL_TO_WH',
	INBOUND_TRUCKING_FROM_MILL_TO_PLANT = 'INBOUND_TRUCKING_FROM_MILL_TO_PLANT',
	WAREHOUSING_STUFFING_DRAY_TO_PORT = 'WAREHOUSING_STUFFING_DRAY_TO_PORT',
	LOADING_FLATRACK_AND_DRAY_TO_PORT = 'LOADING_FLATRACK_AND_DRAY_TO_PORT',
	AVERAGE_NUMBER_OF_CONTAINER = 'AVERAGE_NUMBER_OF_CONTAINER',
}

export enum AddOnCostPer {
	CONTAINER = 'CONTAINER',
	BL = 'BL',
	PERCENT_MATERIAL_VALUE = 'PERCENT_MATERIAL_VALUE',
	BL_STD_CONTAINER = 'BL_STD_CONTAINER',
	CONTAINER_EQUIV = 'CONTAINER_EQUIV',
	TRUCKLOAD = 'TRUCKLOAD',
	FLATRACK_CONTAINER = 'FLATRACK_CONTAINER',
	BASE_UOM = 'BASE_UOM',
}

export interface CreateAddOnCostRequest {
	type: AddOnCostType,
	category: AddOnCostCategory,
	per: AddOnCostPer,
	price: number,
	currency: string,
	active: boolean,
	description: string,
}

export interface AddOnCost {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	type: AddOnCostType,
	category: AddOnCostCategory,
	per: AddOnCostPer,
	price: number,
	currency: string,
	active: boolean,
	description: string,
}

export interface CustomerCreditView {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	currentAmount?: number | null,
	late1To15?: number | null,
	late16To30?: number | null,
	late31To45?: number | null,
	late46Plus?: number | null,
	totalAmount?: number | null,
	unappliedChecks?: number | null,
}

export interface CustomerImportResultView {
	customerId: number,
	customerCode: string,
	customerName: string,
	shipToCodes: Array<string>,
}

export interface DocumentView {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	attachmentId: number,
	type: DocumentType,
	number: number,
	active: boolean,
	name?: string | null,
	url?: string | null,
	source: DocumentViewSource,
}

export enum DocumentViewSource {
	TB = 'TB',
	MSR = 'MSR',
}

export interface CreateDutyCostRequest {
	htsNumber: string,
	dutyRate?: string | null,
	section301AdditionalTariffChina?: string | null,
	softwoodLumberFeeMbf?: string | null,
	softwoodLumberFeeMbfCurrency?: string | null,
	softwoodLumberFeeCbm?: string | null,
	softwoodLumberFeeCbmCurrency?: string | null,
	addCvdNotCalculated?: string | null,
	brazilUnderGspNotCalculated?: string | null,
	active: boolean,
}

export interface UpdateDutyCostRequest {
	dutyRate?: string | null,
	section301AdditionalTariffChina?: string | null,
	softwoodLumberFeeMbf?: string | null,
	softwoodLumberFeeMbfCurrency?: string | null,
	softwoodLumberFeeCbm?: string | null,
	softwoodLumberFeeCbmCurrency?: string | null,
	addCvdNotCalculated?: string | null,
	brazilUnderGspNotCalculated?: string | null,
	active: boolean,
}

export interface DutyCostView {
	htsNumber: string,
	dateCreated: string,
	dateUpdated: string,
	dutyRate?: string | null,
	section301AdditionalTariffChina?: string | null,
	softwoodLumberFeeMbf?: string | null,
	softwoodLumberFeeMbfCurrency?: string | null,
	softwoodLumberFeeCbm?: string | null,
	softwoodLumberFeeCbmCurrency?: string | null,
	addCvdNotCalculated?: string | null,
	brazilUnderGspNotCalculated?: string | null,
	active: boolean,
}

export enum FeatureFlag {
	TEST_FLAG = 'TEST_FLAG',
	SHIP_VIA_UNIT_TYPES = 'SHIP_VIA_UNIT_TYPES',
}

export interface CreateLengthRequest {
	unit: string,
	value: string,
	actual: number,
	nominal: number,
	sopo: boolean,
	active: boolean,
}

export interface LengthUpdate {
	unit: string,
	value: string,
	actual: number,
	nominal: number,
	sopo: boolean,
	active: boolean,
}

export interface Length {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	unit: string,
	value: string,
	actual: number,
	nominal: number,
	sopo: boolean,
	active: boolean,
}

export interface SeaFreightCostImportRow {
	category?: string | null,
	cost: number,
	currency: string,
	destinationDischargeCode?: string | null,
	destinationDischargeId?: number | null,
	destinationLoadingCode?: string | null,
	destinationLoadingId?: number | null,
	incotermDischarge?: string | null,
	incotermLoading?: string | null,
	portOfLoadingCode: string,
	portOfLoadingId: number,
	portOfDischargeCode: string,
	portOfDischargeId: number,
	validDate?: string | null,
	validDateObject?: string | null,
	vendor?: string | null,
	invalid: boolean,
	invalidMsg: string,
	transitDurationDays?: number | null,
	notes?: string | null,
	id: number,
}

export interface TimeSeriesCategoricalMetric {
	period?: string | null,
	data?: Array<TimeSeriesCategoricalData> | null,
}

export interface TimeSeriesCategoricalData {
	category?: string | null,
	categoryId?: number | null,
	count?: number | null,
	value?: number | null,
	currency?: string | null,
}

export interface ConfirmOfferRequest {
	typeOfPurchase: OfferTypeOfPurchase,
	shipVia: string,
	unitTypeCode?: string | null,
	dueByDate?: string | null,
	locationCode?: string | null,
	quoteId?: number | null,
}

export enum OfferTypeOfPurchase {
	DIRECT = 'DIRECT',
	STOCK_050 = 'STOCK_050',
	PLANT_SALE = 'PLANT_SALE',
}

export interface CreateOfferRequest {
	vendorCompanyId?: number | null,
	shipFromLocationId?: number | null,
	incoterm: string,
	incotermAddressDestination: string,
	incotermPortIdDestination?: number | null,
	dateIncoDestination?: string | null,
	validityDate?: string | null,
	externalComment: string,
	internalComment: string,
	status: OfferStatus,
	currency: string,
	products?: Array<CreateOfferProductRequest> | null,
	requestForQuoteId?: number | null,
	quotes?: Array<number> | null,
	attachments?: Array<number> | null,
}

export interface CreateOfferProductRequest {
	productId: number,
	uom: ProductV2UOM,
	lengthIds: Array<number>,
	quantity: number,
	price: number,
	cost: number,
}

export interface OfferUpdate {
	active: boolean,
	vendorCompanyId?: number | null,
	shipFromLocationId?: number | null,
	incoterm: string,
	incotermAddressDestination: string,
	incotermPortIdDestination?: number | null,
	dateIncoDestination?: string | null,
	validityDate?: string | null,
	externalComment: string,
	internalComment: string,
	status: OfferStatus,
	buyerUserId: number,
	currency: string,
	products?: Array<UpdateOfferProductRequest> | null,
	requestForQuoteId?: number | null,
	quotes?: Array<number> | null,
	attachments?: Array<number> | null,
}

export interface UpdateOfferProductRequest {
	id: number,
	offerId: number,
	productId: number,
	uom: ProductV2UOM,
	lengthIds: Array<number>,
	quantity: number,
	price: number,
	cost: number,
}

export interface CreateOfferFeedbackRequest {
	value: boolean,
	message: string,
}

export interface CreateQuoteFromOfferRequest {
	accountManagerUserId?: number | null,
	customerCompanyId?: number | null,
	shipToLocationId: number,
	incoterm: string,
	incotermAddressDestination: string,
	incotermPortIdDestination?: number | null,
	expectedDeliveryDate?: string | null,
	products: Array<CreateQuoteProductRequest>,
}

export interface CreateQuoteProductRequest {
	productId: number,
	uom: string,
	lengthIds: Array<number>,
	quantity: number,
	price: number,
	cost: number,
	margin: number,
	currency: string,
	active: boolean,
}

export interface Offer {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	createdByUserAccountId?: number | null,
	updatedByUserAccountId?: number | null,
	active: boolean,
	vendorCompanyId?: number | null,
	shipFromLocationId?: number | null,
	incoterm: string,
	incotermAddressDestination: string,
	incotermPortIdDestination?: number | null,
	dateIncoDestination?: string | null,
	validityDate?: string | null,
	externalComment: string,
	internalComment: string,
	status: OfferStatus,
	buyerUserId?: number | null,
	currency: string,
	requestForQuoteId?: number | null,
	typeOfPurchase?: OfferTypeOfPurchase | null,
}

export enum OfferStatus {
	RFO_DRAFT = 'RFO_DRAFT',
	RFO_SENT = 'RFO_SENT',
	RFO_DECLINED = 'RFO_DECLINED',
	RFO_ARCHIVED = 'RFO_ARCHIVED',
	RFO_DRAFT_VENDOR = 'RFO_DRAFT_VENDOR',
	O_DRAFT = 'O_DRAFT',
	O_DRAFT_VENDOR = 'O_DRAFT_VENDOR',
	O_BUYER_REVIEW = 'O_BUYER_REVIEW',
	O_SALES_REVIEW = 'O_SALES_REVIEW',
	O_QUOTED = 'O_QUOTED',
	O_IN_PROGRESS = 'O_IN_PROGRESS',
	O_QUOTE_CLOSED = 'O_QUOTE_CLOSED',
	O_CLOSING = 'O_CLOSING',
	O_VENDOR_CONFIRMED = 'O_VENDOR_CONFIRMED',
	O_ORDERED = 'O_ORDERED',
	O_DELIVERED = 'O_DELIVERED',
	O_ARCHIVED = 'O_ARCHIVED',
}

export interface OfferFeedback {
	offerId: number,
	userAccountId: number,
	dateCreated: string,
	dateUpdated: string,
	value: boolean,
	message: string,
}

export interface OfferProduct {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	offerId: number,
	productId: number,
	uom: ProductV2UOM,
	quantity: number,
	price: number,
	cost: number,
	lengthIds: Array<number>,
}

export interface OfferMatchedProduct extends MyProduct {
	offerProductId: number,
	quantity: number,
	offerProductPrice: number,
	calculatedPrice?: PriceCalculationResults | null,
	offerProductUom: ProductV2UOM,
	offerLengthIds: Array<number>,
}

export interface OfferMatchingResult {
	locationId: number,
	locationName: string,
	accountManagerUserId: number,
	incoterm: string,
	defaultMargin: number,
	companyId: number,
	companyCode: string,
	companyName: string,
	portId?: number | null,
	portName?: string | null,
	matchedProducts: Array<number>,
	lastQuoteReceived?: string | null,
	lastOrder?: string | null,
	transitTimeDays?: number | null,
	address?: string | null,
}

export interface OfferView extends Offer {
	products: Array<OfferProductView>,
	members: Array<number>,
	quotes: Array<number>,
	vendorCompanyName?: string | null,
	shipFromName?: string | null,
	incotermPortName?: string | null,
	attachments?: Array<number> | null,
	buyerName: string,
	purchaseOrders: Array<number>,
	unreadChatMessages: number,
	accountManagerName?: string | null,
	quoteAccountManagerName?: string | null,
	shipToName?: string | null,
	readMark: boolean,
}

export interface OfferProductView extends OfferProduct {
	productCode: string,
	productType: string,
	productDescription: string,
	productGroupName: string,
	unitsContainer: number,
	productActThick: number,
	productActWidth: number,
	productActLength: number,
	productGroupId: number,
	hasSyndigo: boolean,
}

export interface OffersByStatusSummary {
	status: OfferStatus,
	currency: string,
	totalValue: number,
	numberOfOffers: number,
}

export interface PriceCalculationResults {
	currency: string,
	productBaseUom: ProductV2UOM,
	offerUom: ProductV2UOM,
	quoteUom: ProductV2UOM,
	quantityOfferUom: number,
	baseUnitsPerContainer: number,
	quoteType: SeaFreightCostCategory,
	seaFreightCostsForContainer: number,
	seaFreightCostsCurrency: string,
	margin: number,
	numberOfPcs: number,
	MbfPerPcs: number,
	CbmPerPcs: number,
	productCostsPerPcs: number,
	productCostsInBaseUom: number,
	productCostsInQuoteUom: number,
	logisticCostPerBaseUom: number,
	seaFreightCostsPerPcs: number,
	seaFreightCostsPerQuoteUom: number,
	addOnCostsPerBaseUom: number,
	addOnCostsPerPcs: number,
	addOnCostsPerQuoteUom: number,
	costs: number,
	sellPrice: number,
	userAccountId: number,
	dateCreated: string,
	notes: Array<string>,
	exchangeRate: number,
	excludedAddons?: Array<number> | null,
	seaFreightCostsId?: number | null,
}

export interface ItemCalculatedPriceView {
	totalItemPricePerUnit: number,
	priceUom: ProductV2UOM,
	currency: string,
	productCode: string,
	category?: SeaFreightCostCategory | null,
	warnings: Array<string>,
	errors: Array<string>,
	materialCostView?: MaterialCostPriceView | null,
	logisticsCostView?: LogisticsCostView | null,
	marginView?: MarginView | null,
}

export interface MaterialCostPriceView {
	productNominalActual: string,
	materialCostPerBaseUom: number,
	baseUom: string,
	materialCostPerQuoteUom: number,
	notes?: Array<string> | null,
	warnings?: Array<string> | null,
	calculations?: Array<string> | null,
}

export interface LogisticsCostView {
	portOfLoadingName?: string | null,
	portOfDischargeName?: string | null,
	totalCostsPerUom?: number | null,
	duties?: ItemDutyListView | null,
	addOnCosts: Array<AddOnCostsView>,
	unitsPerContainer?: number | null,
	unitsPerTruckload?: number | null,
	avgNumberOfContainers?: number | null,
	logisticsCostPerContainer?: number | null,
	logisticsCostPerUom?: number | null,
	errors: Array<string>,
	warnings?: Array<string> | null,
	calculations?: Array<string> | null,
}

export interface ItemDutyListView {
	duties?: Array<ItemDutyView> | null,
	totalDutyCostsPerUom?: number | null,
}

export interface ItemDutyView {
	dutyCostPerUom: number,
	name: string,
	dutyCost: number,
	calculation: string,
}

export interface AddOnCostsView {
	label: string,
	totalAddOnCostsPerUom: number,
	addOns: Array<AddOnView>,
	excludedAddOns: Array<AddOnView>,
	costsSum: number,
	per: string,
	calculation: string,
}

export interface AddOnView {
	name: string,
	cost: number,
	per: string,
}

export interface MarginView {
	marginPercent: number,
	marginCostPerUom?: number | null,
	notes: Array<string>,
	warnings: Array<string>,
}

export interface ProductGroupUpdate {
	buyerUserIds: Array<number>,
	incoterm: string,
	portOfLoadingId?: number | null,
	address: string,
	unitOfMeasure: string,
	minOrderQuantity: string,
	productGroupMargin: number,
	price: number,
	currency: string,
	validityDate?: string | null,
	lengthIds: Array<number>,
	productPrices: Array<ProductGroupUpdatePricesProduct>,
}

export interface ProductGroupUpdatePricesProduct {
	id: number,
	price: number,
}

export interface ProductGroupUpdatePrices {
	price: number,
	validityDate?: string | null,
	productPrices: Array<ProductGroupUpdatePricesProduct>,
}

export interface Filters {
	group: Array<string>,
	category: Array<string>,
	species: Array<string>,
	surface: Array<string>,
	finish: Array<string>,
	grade: Array<string>,
	dryness: Array<string>,
	construction: Array<string>,
	type: Array<string>,
	classCode: Array<string>,
	lengthCode: Array<string>,
}

export interface NewProductSales {
	companyId: number,
	locationId: number,
	productId: number,
	productCode: string,
	dateCreated: string,
	deliveryId: number,
	salesOrderNumber: string,
	rn: number,
}

export interface AddCatalogueProductsToCompanyRequest {
	companyId: number,
	categoryNames: Array<string>,
	productIds: Array<number>,
	locationIds: Array<number>,
}

export interface CompanyProductUpdate {
	productId: number,
	lengthIds: Array<number>,
	uom: string,
}

export interface CreateCompanyProductRequest {
	companyId: number,
	locationId?: number | null,
	productId: number,
	lengthIds?: Array<number> | null,
	uom: string,
}

export interface CreateMyProductRequest {
	locationId?: number | null,
	productId: number,
	lengthIds?: Array<number> | null,
	uom: string,
}

export interface ProductV2 {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	productMsrId: number,
	productType: ProductV2ProductType,
	productCode: string,
	description: string,
	productCategory: string,
	species: string,
	surfaceCode: string,
	finish: string,
	grade: string,
	drynessCode: string,
	construction: string,
	type: string,
	classCode: string,
	isImport: boolean,
	isExport: boolean,
	isDomestic: boolean,
	exportManufacturing: boolean,
	foreignToForeign: boolean,
	nominalActual: ProductV2ActualNominal,
	standardUnitOfMeasure: ProductV2UOM,
	unitsContainer: number,
	unitsTL: number,
	rlPriceId: boolean,
	pcsBdl: number,
	productGroupId: number,
	price: number,
	currency?: string | null,
	active: boolean,
	hasSyndigo: boolean,
}

export enum ProductV2ProductType {
	LUMBER = 'LUMBER',
	LINEAR_FOOTAGE = 'LINEAR_FOOTAGE',
	PANELS = 'PANELS',
	SPECIAL_PRODUCTS = 'SPECIAL_PRODUCTS',
	FINANCE = 'FINANCE',
}

export enum ProductV2ActualNominal {
	ACTUAL = 'ACTUAL',
	NOMINAL = 'NOMINAL',
}

export enum ProductV2UOM {
	MBF = 'MBF',
	CBM = 'CBM',
	PCS = 'PCS',
	MSF = 'MSF',
	LF = 'LF',
	FI = 'FI',
}

export interface CreateProductRequest {
	productType: ProductV2ProductType,
	productCode: string,
	description: string,
	productCategory: string,
	species: string,
	surfaceCode: string,
	finish: string,
	grade: string,
	drynessCode: string,
	construction: string,
	type: string,
	classCode: string,
	isImport: boolean,
	isExport: boolean,
	isDomestic: boolean,
	exportManufacturing: boolean,
	foreignToForeign: boolean,
	nominalActual: ProductV2ActualNominal,
	standardUnitOfMeasure: ProductV2UOM,
	unitsContainer: number,
	unitsTL: number,
	rlPriceId: boolean,
	pcsBdl: number,
	price: number,
	currency?: string | null,
	msrProductTypeCode: string,
	msrDescription: string,
	msrHarmonizedCode: string,
	msrNomThick: number,
	msrNomWidth: number,
	msrActThick: number,
	msrActWidth: number,
	msrActLength: number,
	msrLengthCode: string,
	msrLengthDesc: string,
	msrSpeciesCode: string,
	msrSpeciesDesc: string,
	msrGradeCode: string,
	msrGradeDesc: string,
	msrTypeCode: string,
	msrTypeDesc: string,
	msrSizeCode: string,
	msrTreatmentCode: string,
	msrTreatmentDesc: string,
	msrGroupCode: string,
	msrGroupDesc: string,
	msrSurfaceCode: string,
	msrSurfaceDesc: string,
	msrDrynessCode: string,
	msrDrynessDesc: string,
	msrFlnThkCode: string,
	msrFlnThkDesc: string,
	msrDepWidCode: string,
	msrDepWidDesc: string,
	msrMoeCode: string,
	msrMoeDesc: string,
	msrConstructionCode: string,
	msrConstructionDesc: string,
	msrThicknessCode: string,
	msrCategoryCode: string,
	msrCategoryDesc: string,
	msrClassCode: string,
	msrClassDesc: string,
	msrTreatingCode: string,
	msrTreatingDesc: string,
}

export interface MyProduct extends ProductUfp {
	companyId: number,
	locationId?: number | null,
	productId: number,
	uom: ProductV2UOM,
	lengthIds: Array<number>,
	lengthValues: Array<string>,
}

export interface ProductCategoryView {
	name: string,
	productsCount: number,
}

export interface ProductGroupPublicView {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	type: string,
	name: string,
	unitOfMeasure: string,
	minOrderQuantity: string,
	lengthIds: Array<number>,
	qualityIds: Array<number>,
	portName?: string | null,
	productsCount: number,
}

export interface ProductGroupView {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	type: string,
	name: string,
	buyerUserIds: Array<number>,
	incoterm: string,
	portOfLoadingId?: number | null,
	address: string,
	unitOfMeasure: string,
	minOrderQuantity: string,
	productGroupMargin: number,
	price: number,
	currency: string,
	validityDate?: string | null,
	updatedByUserId?: number | null,
	lengthIds: Array<number>,
	portName?: string | null,
	productsCount: number,
}

export interface ProductPublicView {
	id: number,
	productType: string,
	productCode: string,
	description: string,
	productGroupId: number,
	nominalActual: string,
	standardUnitOfMeasure: string,
	unitsContainer: number,
	rlPriceId: boolean,
	msrNomThick: number,
	msrNomWidth: number,
	msrActThick: number,
	msrActWidth: number,
	msrActLength: number,
	productGroup: string,
	hasSyndigo: boolean,
}

export interface ProductUfp {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	productMsrId: number,
	productType: ProductV2ProductType,
	productCode: string,
	description: string,
	productCategory: string,
	species: string,
	surfaceCode: string,
	finish: string,
	grade: string,
	drynessCode: string,
	construction: string,
	type: string,
	classCode: string,
	isImport: boolean,
	isExport: boolean,
	isDomestic: boolean,
	exportManufacturing: boolean,
	foreignToForeign: boolean,
	nominalActual: ProductV2ActualNominal,
	standardUnitOfMeasure: ProductV2UOM,
	unitsContainer: number,
	unitsTL: number,
	rlPriceId: boolean,
	pcsBdl: number,
	productGroupId: number,
	price: number,
	currency?: string | null,
	msrProductTypeCode: string,
	msrDescription: string,
	msrHarmonizedCode: string,
	msrNomThick: number,
	msrNomWidth: number,
	msrActThick: number,
	msrActWidth: number,
	msrActLength: number,
	msrLengthCode: string,
	msrLengthDesc: string,
	msrSpeciesCode: string,
	msrSpeciesDesc: string,
	msrGradeCode: string,
	msrGradeDesc: string,
	msrTypeCode: string,
	msrTypeDesc: string,
	msrSizeCode: string,
	msrTreatmentCode: string,
	msrTreatmentDesc: string,
	msrGroupCode: string,
	msrGroupDesc: string,
	msrSurfaceCode: string,
	msrSurfaceDesc: string,
	msrDrynessCode: string,
	msrDrynessDesc: string,
	msrFlnThkCode: string,
	msrFlnThkDesc: string,
	msrDepWidCode: string,
	msrDepWidDesc: string,
	msrMoeCode: string,
	msrMoeDesc: string,
	msrConstructionCode: string,
	msrConstructionDesc: string,
	msrThicknessCode: string,
	msrCategoryCode: string,
	msrCategoryDesc: string,
	msrClassCode: string,
	msrClassDesc: string,
	msrTreatingCode: string,
	msrTreatingDesc: string,
	productGroup: string,
	productGroupPrice: number,
	active: boolean,
	hasSyndigo: boolean,
}

export interface UpdatePromiseDateData {
	promiseDate: string,
}

export interface CreateMultiplePurchaseOrderDocumentsRequest {
	orderIds: Array<number>,
	documents: Array<CreatePurchaseOrderDocumentRequest>,
}

export interface CreatePurchaseOrderDocumentRequest {
	attachmentId: number,
	type: DocumentType,
	number: number,
}

export enum PurchaseOrderStatus {
	REQUESTED = 'REQUESTED',
	ORDERED = 'ORDERED',
	PARTIAL = 'PARTIAL',
	INBOUND = 'INBOUND',
	COMPLETE = 'COMPLETE',
	ACKNOWLEDGED = 'ACKNOWLEDGED',
	VOID = 'VOID',
}

export interface PurchaseOrderItemPlantView {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	productType?: string | null,
	productCode?: string | null,
	lengthCode?: string | null,
	description?: string | null,
	plantCost?: number | null,
	pieces?: number | null,
	packages?: number | null,
	piecesPerPackage?: number | null,
	plantCostCurrency?: string | null,
	plantCostUom?: string | null,
	plantVolume?: number | null,
	hasSyndigo: boolean,
	productId?: number | null,
}

export interface PurchaseOrderPlantView {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	branchCode?: string | null,
	type?: string | null,
	number?: string | null,
	status?: PurchaseOrderStatus | null,
	shipFromCode?: string | null,
	shipVia?: string | null,
	shipTerms?: string | null,
	masterPo?: number | null,
	masterChild?: string | null,
	tarp?: string | null,
	pickupReady?: string | null,
	pickupNumber?: string | null,
	claimStatus?: string | null,
	claimDate?: string | null,
	claimType?: string | null,
	plantCostTotal?: number | null,
	dueDate?: string | null,
	scheduledDeliveryDate?: string | null,
	requestDate?: string | null,
	orderedDate?: string | null,
	shipWeekOfDate?: string | null,
	actualShipDate?: string | null,
	promiseDate?: string | null,
	promiseDateRevised?: string | null,
	promiseDateConfirmed: boolean,
	scheduledPickupDate?: string | null,
	importPo?: string | null,
	importBol?: string | null,
	importVessel?: string | null,
	importVoyage?: string | null,
	importContainer?: string | null,
	importVesselDischargeDate?: string | null,
	importArrivalNoticeDate?: string | null,
	importCustomsReleaseDate?: string | null,
	importEtaPortDate?: string | null,
	importPortOfLoad?: string | null,
	importPortOfDestination?: string | null,
	importSteamshipLine?: string | null,
	importBolHouse?: string | null,
	importIsfReceived?: string | null,
	importContainerCount?: number | null,
	importCexmHold?: boolean | null,
	importLastFreeDate?: string | null,
	importSailingVessel?: string | null,
	importSailingVoyage?: string | null,
	importIncoTerms?: string | null,
	importIncoTermsLoc?: string | null,
	importFreightForwarder?: string | null,
	importDocsReceivedDate?: string | null,
	buyerCode?: string | null,
	buyerName?: string | null,
	locationCode?: string | null,
	locationType?: string | null,
	locationName?: string | null,
	contractNumber?: number | null,
	orderNumber?: string | null,
	shipToName?: string | null,
	addonFreightCarrierCode?: string | null,
	addonFreightCarrierName?: string | null,
	addonFreightEstimatedAmount?: number | null,
	purchasingBranch?: string | null,
	createdBy?: string | null,
	createdByName?: string | null,
	createdDate?: string | null,
	currency?: string | null,
	plantLocationId?: number | null,
	buyerUserAccountId?: number | null,
	vendorName?: string | null,
	shipFromName?: string | null,
	members: Array<number>,
}

export interface PurchaseOrderItemView {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	purchaseOrderId: number,
	branchCode?: string | null,
	orderNumber?: number | null,
	itemNumber: number,
	productType?: string | null,
	productCode?: string | null,
	lengthCode?: string | null,
	description?: string | null,
	millCost?: number | null,
	millCostTotal?: number | null,
	currency?: string | null,
	pieces?: number | null,
	packages?: number | null,
	piecesPerPackage?: number | null,
	volume?: number | null,
	volumeUom?: string | null,
	metricQuantity?: number | null,
	buyUom?: string | null,
	buyCost?: number | null,
	hasSyndigo: boolean,
	productId?: number | null,
}

export interface PurchaseOrderView {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	branchCode?: string | null,
	type?: string | null,
	number?: string | null,
	status?: PurchaseOrderStatus | null,
	shipFromCode?: string | null,
	vendorContact?: string | null,
	vendorConfirmation?: string | null,
	shipVia?: string | null,
	shipTerms?: string | null,
	masterPo?: number | null,
	masterChild?: string | null,
	invoiceTerms?: string | null,
	invoiceTermsDescription?: string | null,
	tarp?: string | null,
	pickupReady?: string | null,
	pickupNumber?: string | null,
	claimStatus?: string | null,
	claimDate?: string | null,
	claimType?: string | null,
	millCostTotal?: number | null,
	dueDate?: string | null,
	scheduledDeliveryDate?: string | null,
	requestDate?: string | null,
	orderedDate?: string | null,
	shipWeekOfDate?: string | null,
	actualShipDate?: string | null,
	promiseDate?: string | null,
	promiseDateRevised?: string | null,
	promiseDateConfirmed: boolean,
	readyAtMillDate?: string | null,
	scheduledPickupDate?: string | null,
	importPo?: string | null,
	importBol?: string | null,
	importVessel?: string | null,
	importVoyage?: string | null,
	importContainer?: string | null,
	importVesselDischargeDate?: string | null,
	importArrivalNoticeDate?: string | null,
	importCustomsReleaseDate?: string | null,
	importEtaPortDate?: string | null,
	importPortOfLoad?: string | null,
	importPortOfDestination?: string | null,
	importSteamshipLine?: string | null,
	importBolHouse?: string | null,
	importIsfReceived?: string | null,
	importContainerCount?: number | null,
	importCexmHold?: boolean | null,
	importLastFreeDate?: string | null,
	importSailingVessel?: string | null,
	importSailingVoyage?: string | null,
	importIncoTerms?: string | null,
	importIncoTermsLoc?: string | null,
	importFreightForwarder?: string | null,
	importDocsReceivedDate?: string | null,
	buyerCode?: string | null,
	buyerName?: string | null,
	vendorCode?: string | null,
	locationCode?: string | null,
	locationType?: string | null,
	locationName?: string | null,
	contractNumber?: number | null,
	contractVendorCode?: string | null,
	orderNumber?: string | null,
	shipToName?: string | null,
	addonFreightCarrierCode?: string | null,
	addonFreightCarrierName?: string | null,
	addonFreightEstimatedAmount?: number | null,
	purchasingBranch?: string | null,
	createdBy?: string | null,
	createdByName?: string | null,
	createdDate?: string | null,
	currency?: string | null,
	importMd5?: string | null,
	shipFromLocationId?: number | null,
	vendorCompanyId?: number | null,
	buyerUserAccountId?: number | null,
	salesOrderId?: number | null,
	offerId?: number | null,
	accountManagerUserId?: number | null,
	accountManagerName?: string | null,
	vendorName?: string | null,
	shipFromName?: string | null,
	members: Array<number>,
	documentCount?: number | null,
}

export interface ConfirmQuoteRequest {
	shipVia: string,
	customerPo: string,
}

export interface CreateQuoteRequest {
	accountManagerUserId?: number | null,
	customerCompanyId?: number | null,
	shipToLocationId?: number | null,
	incoterm: string,
	incotermAddressDestination: string,
	incotermPortIdDestination?: number | null,
	portOfLoadingId?: number | null,
	validityDate?: string | null,
	externalComment: string,
	internalComment: string,
	status: QuoteStatus,
	currency: string,
	offerId?: number | null,
	active: boolean,
	products?: Array<CreateQuoteProductRequest> | null,
	expectedDeliveryDate?: string | null,
	requestForQuoteId?: number | null,
	vendorCompanyId?: number | null,
	buyerUserId?: number | null,
	shipFromLocationId?: number | null,
	customerContacts?: Array<number> | null,
	attachments?: Array<number> | null,
}

export interface QuoteUpdate {
	id: number,
	accountManagerUserId?: number | null,
	customerCompanyId?: number | null,
	shipToLocationId?: number | null,
	incoterm: string,
	incotermAddressDestination: string,
	incotermPortIdDestination?: number | null,
	portOfLoadingId?: number | null,
	validityDate?: string | null,
	externalComment: string,
	internalComment: string,
	status: QuoteStatus,
	currency: string,
	offerId?: number | null,
	active: boolean,
	products?: Array<QuoteProductUpdate> | null,
	expectedDeliveryDate?: string | null,
	requestForQuoteId?: number | null,
	vendorCompanyId?: number | null,
	buyerUserId?: number | null,
	shipFromLocationId?: number | null,
	customerContacts?: Array<number> | null,
	attachments?: Array<number> | null,
}

export interface QuoteProductUpdate {
	id: number,
	quoteId: number,
	productId: number,
	uom: string,
	lengthIds: Array<number>,
	quantity: number,
	price: number,
	cost: number,
	margin: number,
	currency: string,
	active: boolean,
}

export interface CreateQuoteFeedbackRequest {
	value: boolean,
	message: string,
}

export interface Quote {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	active: boolean,
	accountManagerUserId?: number | null,
	customerCompanyId?: number | null,
	shipToLocationId?: number | null,
	incoterm: string,
	incotermAddressDestination: string,
	incotermPortIdDestination?: number | null,
	validityDate?: string | null,
	externalComment: string,
	status: QuoteStatus,
	currency: string,
	offerId?: number | null,
	internalComment: string,
	expectedDeliveryDate?: string | null,
	requestForQuoteId?: number | null,
	createdByUserAccountId?: number | null,
	vendorCompanyId?: number | null,
	buyerUserId?: number | null,
	shipFromLocationId?: number | null,
	portOfLoadingId?: number | null,
	updatedByUserAccountId?: number | null,
}

export enum QuoteStatus {
	DRAFT = 'DRAFT',
	SENT = 'SENT',
	INTERESTED = 'INTERESTED',
	TO_BE_CONFIRMED = 'TO_BE_CONFIRMED',
	ORDERED = 'ORDERED',
	DELIVERED = 'DELIVERED',
	ARCHIVED = 'ARCHIVED',
}

export interface QuoteProduct {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	quoteId: number,
	productId: number,
	uom: string,
	quantity: number,
	price: number,
	cost: number,
	margin: number,
	currency: string,
	lengthIds: Array<number>,
	active: boolean,
}

export interface QuoteView extends Quote {
	offerDateUpdated?: string | null,
	members: Array<number>,
	attachments: Array<number>,
	customerContacts: Array<number>,
	customerCompanyName: string,
	shipToName: string,
	accountManagerName?: string | null,
	shipToAccountManagerName?: string | null,
	salesOrderIds: Array<number>,
	readMark: boolean,
	offerProductCount?: number | null,
	unreadMessagesCount: number,
	products: Array<QuoteProductView>,
}

export interface QuoteProductView extends QuoteProduct {
	productCode: string,
	productType: string,
	productDescription: string,
	productGroupName: string,
	productActThick: number,
	productActWidth: number,
	productActLength: number,
	unitsContainer: number,
	productGroupId: number,
	calculatedPrice?: CalculatedPriceView | null,
	priceHistory?: Array<PriceChangeHistoryItem> | null,
	hasSyndigo: boolean,
}

export interface CalculatedPriceView {
	id: number,
	dateCreated: string,
	userAccountId: number,
	currency: string,
	productBaseUom: ProductV2UOM,
	offerUom: ProductV2UOM,
	quoteUom: ProductV2UOM,
	quantityOfferUom: number,
	baseUnitsPerContainer: number,
	quoteType: QuoteType,
	seaFreightCostsForContainer: number,
	seaFreightCostsCurrency: string,
	margin: number,
	numberOfPcs: number,
	MbfPerPcs: number,
	CbmPerPcs: number,
	productCostsPerPcs: number,
	productCostsInBaseUom: number,
	productCostsInQuoteUom: number,
	logisticCostPerBaseUom: number,
	seaFreightCostsPerPcs: number,
	seaFreightCostsPerQuoteUom: number,
	addOnCostsPerBaseUom: number,
	addOnCostsPerPcs: number,
	addOnCostsPerQuoteUom: number,
	costs: number,
	sellPrice: number,
	notes: Array<string>,
}

export enum QuoteType {
	F2F = 'F2F',
	Export = 'Export',
	ExportMan = 'ExportMan',
	Import = 'Import',
}

export interface PriceChangeHistoryItem {
	historyId: number,
	historyTimestamp: string,
	userAccountId: number,
	quoteProductId: number,
	price: number,
	currency: string,
}

export interface QuotesByCustomerSummary extends Company {
	currency: string,
	totalValue: number,
	numberOfQuotes: number,
}

export interface QuotesByOfferSummary extends Offer {
	quoteCurrency: string,
	totalValue: number,
	numberOfQuotes: number,
	numberOfProducts: number,
	buyerName?: string | null,
}

export interface QuotesByShipToSummary extends Location {
	currency: string,
	totalValue: number,
	numberOfQuotes: number,
	companyName: string,
}

export interface QuotesByStatusSummary {
	status: QuoteStatus,
	currency: string,
	totalValue: number,
	numberOfQuotes: number,
}

export interface UpdateRandomLengthLumberRequest {
	rlType?: string | null,
	oneByTwoPrimeAdder?: number | null,
	inboundFreight?: number | null,
	milling?: number | null,
	treating?: number | null,
	outboundFreight?: number | null,
	price8Inches?: number | null,
	price10Inches?: number | null,
	price12Inches?: number | null,
	price14Inches?: number | null,
	price16Inches?: number | null,
	price18Inches?: number | null,
	price20Inches?: number | null,
	price22Inches?: number | null,
	price24Inches?: number | null,
	code8Inches?: string | null,
	code10Inches?: string | null,
	code12Inches?: string | null,
	code14Inches?: string | null,
	code16Inches?: string | null,
	code18Inches?: string | null,
	code20Inches?: string | null,
	code22Inches?: string | null,
	code24Inches?: string | null,
}

export interface UpdateRandomLengthPanelRequest {
	rlType?: string | null,
	inboundFreight?: number | null,
	treating?: number | null,
	outboundFreight?: number | null,
	code?: string | null,
	codeType?: RLPanelCodeType | null,
	price?: number | null,
}

export enum RLPanelCodeType {
	EAST = 'EAST',
	RATED_EAST = 'RATED_EAST',
	MIL_GRADE_EAST = 'MIL_GRADE_EAST',
}

export interface RandomLengthLumberView {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	createdByUserAccountId: number,
	updatedByUserAccountId?: number | null,
	productId: number,
	rlType: string,
	oneByTwoPrimeAdder?: number | null,
	inboundFreight?: number | null,
	milling?: number | null,
	treating?: number | null,
	outboundFreight?: number | null,
	active: boolean,
	code8Inches?: string | null,
	price8Inches?: number | null,
	price8InchesUpdatedByUserAccountId?: number | null,
	price8InchesDate?: string | null,
	code10Inches?: string | null,
	price10Inches?: number | null,
	price10InchesUpdatedByUserAccountId?: number | null,
	price10InchesDate?: string | null,
	code12Inches?: string | null,
	price12Inches?: number | null,
	price12InchesUpdatedByUserAccountId?: number | null,
	price12InchesDate?: string | null,
	code14Inches?: string | null,
	price14Inches?: number | null,
	price14InchesUpdatedByUserAccountId?: number | null,
	price14InchesDate?: string | null,
	code16Inches?: string | null,
	price16Inches?: number | null,
	price16InchesUpdatedByUserAccountId?: number | null,
	price16InchesDate?: string | null,
	code18Inches?: string | null,
	price18Inches?: number | null,
	price18InchesUpdatedByUserAccountId?: number | null,
	price18InchesDate?: string | null,
	code20Inches?: string | null,
	price20Inches?: number | null,
	price20InchesUpdatedByUserAccountId?: number | null,
	price20InchesDate?: string | null,
	code22Inches?: string | null,
	price22Inches?: number | null,
	price22InchesUpdatedByUserAccountId?: number | null,
	price22InchesDate?: string | null,
	code24Inches?: string | null,
	price24Inches?: number | null,
	price24InchesUpdatedByUserAccountId?: number | null,
	price24InchesDate?: string | null,
	/** @deprecated Use priceXInchesUpdatedByUserFullName instead */
	updatedByUserFullName?: string | null,
	price8InchesUpdatedByUserFullName?: string | null,
	price10InchesUpdatedByUserFullName?: string | null,
	price12InchesUpdatedByUserFullName?: string | null,
	price14InchesUpdatedByUserFullName?: string | null,
	price16InchesUpdatedByUserFullName?: string | null,
	price18InchesUpdatedByUserFullName?: string | null,
	price20InchesUpdatedByUserFullName?: string | null,
	price22InchesUpdatedByUserFullName?: string | null,
	price24InchesUpdatedByUserFullName?: string | null,
	productCode: string,
	productDescription?: string | null,
	productDimension?: string | null,
	total?: number | null,
}

export interface RandomLengthPanelView {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	createdByUserAccountId: number,
	updatedByUserAccountId?: number | null,
	productId: number,
	rlType?: string | null,
	inboundFreight?: number | null,
	treating?: number | null,
	outboundFreight?: number | null,
	thickness?: string | null,
	price?: number | null,
	priceDate?: string | null,
	priceUpdatedByUserAccountId?: number | null,
	code?: string | null,
	codeType?: RLPanelCodeType | null,
	active: boolean,
	/** @deprecated Use priceUpdatedByUserAccountId instead */
	updatedByUserFullName?: string | null,
	priceUpdatedByUserFullName?: string | null,
	productCode: string,
	productDescription?: string | null,
	productDimension?: string | null,
	total?: number | null,
}

export interface CreateRequestForQuoteRequest {
	customerCompanyId?: number | null,
	shipToLocationId?: number | null,
	incoterm: string,
	incotermAddressDestination: string,
	incotermPortIdDestination?: number | null,
	validityDate?: string | null,
	externalComment: string,
	internalComment: string,
	status: RequestForQuoteStatus,
	currency: string,
	expectedDeliveryDate?: string | null,
	customerContactUserAccountIds?: Array<number> | null,
	products?: Array<CreateRequestForQuoteProductRequest> | null,
	quotes?: Array<number> | null,
	offers?: Array<number> | null,
	attachments?: Array<number> | null,
}

export interface CreateRequestForQuoteProductRequest {
	productId: number,
	uom: string,
	lengthIds: Array<number>,
	quantity: number,
	price: number,
	active: boolean,
	cost: number,
	margin: number,
}

export interface RequestForQuoteUpdate {
	id: number,
	accountManagerUserId?: number | null,
	customerCompanyId?: number | null,
	shipToLocationId?: number | null,
	incoterm: string,
	incotermAddressDestination: string,
	incotermPortIdDestination?: number | null,
	validityDate?: string | null,
	externalComment: string,
	internalComment: string,
	status: RequestForQuoteStatus,
	currency: string,
	active: boolean,
	expectedDeliveryDate?: string | null,
	customerContactUserAccountIds?: Array<number> | null,
	products?: Array<RequestForQuoteProductUpdate> | null,
	quotes?: Array<number> | null,
	offers?: Array<number> | null,
	attachments?: Array<number> | null,
}

export interface RequestForQuoteProductUpdate {
	id: number,
	quoteId: number,
	productId: number,
	uom: string,
	lengthIds: Array<number>,
	quantity: number,
	price: number,
	active: boolean,
	cost: number,
	margin: number,
}

export interface CreateOfferFromRequestForQuoteRequest {
	vendorCompanyId?: number | null,
	shipFromLocationId?: number | null,
	incoterm: string,
	incotermAddressDestination: string,
	incotermPortIdDestination?: number | null,
	dateIncoDestination?: string | null,
	validityDate?: string | null,
	externalComment: string,
	internalComment: string,
	currency: string,
	products?: Array<CreateOfferProductRequest> | null,
}

export interface RequestForQuote {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	createdByUserAccountId?: number | null,
	updatedByUserAccountId?: number | null,
	active: boolean,
	accountManagerUserId?: number | null,
	customerCompanyId?: number | null,
	shipToLocationId?: number | null,
	incoterm: string,
	incotermAddressDestination: string,
	incotermPortIdDestination?: number | null,
	validityDate?: string | null,
	externalComment: string,
	internalComment: string,
	status: RequestForQuoteStatus,
	currency: string,
	expectedDeliveryDate?: string | null,
}

export enum RequestForQuoteStatus {
	DRAFT_AM = 'DRAFT_AM',
	DRAFT_CUSTOMER = 'DRAFT_CUSTOMER',
	REVIEW = 'REVIEW',
	SENT_TO_BUYER = 'SENT_TO_BUYER',
	OFFER_REQUESTED = 'OFFER_REQUESTED',
	OFFERED = 'OFFERED',
	QUOTED = 'QUOTED',
	ORDERED = 'ORDERED',
	ARCHIVED = 'ARCHIVED',
}

export interface RequestForQuoteProduct {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	requestForQuoteId: number,
	productId: number,
	uom: string,
	quantity: number,
	price: number,
	lengthIds: Array<number>,
	active: boolean,
	cost: number,
	margin: number,
}

export interface RequestForQuoteView extends RequestForQuote {
	customerContactUserAccountIds: Array<number>,
	products: Array<RequestForQuoteProductView>,
	members: Array<number>,
	customerCompanyName: string,
	shipToName: string,
	accountManagerName: string,
	shipToAccountManagerName: string,
	quotes: Array<number>,
	offers: Array<number>,
	attachments: Array<number>,
	unreadChatMessages: number,
	readMark: boolean,
}

export interface RequestForQuoteProductView extends RequestForQuoteProduct {
	productCode: string,
	productType: string,
	productDescription: string,
	productGroupName: string,
	productActThick: number,
	productActWidth: number,
	productActLength: number,
	unitsContainer: number,
	productGroupId: number,
	hasSyndigo: boolean,
}

export interface RequestsForQuotesByShipToSummary extends Location {
	currency: string,
	totalValue: number,
	numberOfRequests: number,
	companyName: string,
}

export interface RequestsForQuotesByStatusSummary {
	status: RequestForQuoteStatus,
	currency: string,
	totalValue: number,
	numberOfRequests: number,
}

export interface RequestsForQuotesMatchedProduct extends MyProduct {
	requestForQuoteProductId: number,
}

export interface RequestsForQuotesMatchingResult {
	locationId: number,
	locationName: string,
	incoterm: string,
	companyId: number,
	buyerUserAccountId: number,
	companyCode: string,
	companyName: string,
	portId?: number | null,
	portName?: string | null,
	matchedProducts: number,
	lastOfferReceived: string,
	lastPurchaseOrder: string,
	address?: string | null,
}

export interface CreateDocumentRequest {
	attachmentId: number,
	type: DocumentType,
	number: number,
}

export interface CreateMultipleDocumentsRequest {
	orderIds: Array<number>,
	documents: Array<CreateDocumentRequest>,
}

export interface UpdateDocumentRequest {
	id: number,
	type: DocumentType,
}

export interface SalesOrderView {
	id: number,
	dateCreated: string,
	dateUpdated: string,
	number: string,
	buyerReferenceNumber?: string | null,
	buyerStatus?: DeliveryBuyerStatus | null,
	buyerMessage?: string | null,
	salesPersonUserId?: number | null,
	dateContractSigned?: string | null,
	datePrepaymentReceived?: string | null,
	dateLcOpeningReceived?: string | null,
	dateFullPaymentReceived?: string | null,
	dateOrderPlacedWithSupplier?: string | null,
	dateSawmillConfirmation?: string | null,
	dateLogisticsBooked?: string | null,
	dateLogisticsConfirmed?: string | null,
	dateEstimatedProvisionDate?: string | null,
	datePickup?: string | null,
	dateLoadingCompleted?: string | null,
	dateEts?: string | null,
	dateVesselDeparture?: string | null,
	dateEta?: string | null,
	dateArrivedPod?: string | null,
	dateExportDocumentsDrafted?: string | null,
	dateExportDocumentsApproved?: string | null,
	dateExportDocumentsSubmitted?: string | null,
	dateBlDrafted?: string | null,
	dateBlApproved?: string | null,
	dateAllDocumentsFinalized?: string | null,
	dateAllOriginalsAvailableOnline?: string | null,
	dateOriginalDocumentsSent?: string | null,
	dateOriginalDocumentsDelivered?: string | null,
	paymentMethod?: string | null,
	agentUserAccountId?: number | null,
	transportType?: string | null,
	currency?: string | null,
	totalSalesForCommission?: number | null,
	prepaidOrderAmount: number,
	directPoNumber?: string | null,
	companyId?: number | null,
	locationId?: number | null,
	locationName?: string | null,
	purchaseOrderId?: number | null,
	vesselNumber?: string | null,
	vesselBookingNumber?: string | null,
	vesselName?: string | null,
	vesselVoyageNumber?: string | null,
	vesselSailDate?: string | null,
	vesselShippingLine?: string | null,
	vesselEquipment?: string | null,
	vesselLoadPort?: string | null,
	vesselDischargePort?: string | null,
	shipTerms?: string | null,
	shipTermsLoc?: string | null,
	masterChild?: string | null,
	accountManagerUserId?: number | null,
	paid?: boolean | null,
	quoteId?: number | null,
	accountManagerName?: string | null,
	salesPersonName?: string | null,
	documentCount: number,
	importCustomsReleaseDate?: string | null,
	importArrivalNoticeDate?: string | null,
	members: Array<number>,
}

export interface InsertShipViaUnitTypeRequest {
	code: string,
	description: string,
	active: boolean,
	isDefault: boolean,
	shipVia: string,
}

export interface ShipViaUnitTypeViewModel {
	shipVia: string,
	defaultUnitType: string,
	unitTypes: MapStringToShipViaUnitTypeInfoView,
}

export type MapStringToShipViaUnitTypeInfoView = {
	[key in string]: ShipViaUnitTypeInfoView
}

export interface ShipViaUnitTypeInfoView {
	id: number,
	description: string,
	active: boolean,
	isDefault: boolean,
}

export interface UpdateShipViaUnitTypeRequest {
	id: number,
	shipVia?: string | null,
	code?: string | null,
	description?: string | null,
	active?: boolean | null,
	isDefault?: boolean | null,
}

export interface ShipViaUnitType {
	id: number,
	shipVia: string,
	code: string,
	description: string,
	active: boolean,
	isDefault: boolean,
}

export interface SyndigoProductViewModel {
	id: number,
	fkDefaultSubproductId?: number | null,
	productCode: string,
	attributes?: MapStringToString | null,
	assets?: MapStringToSyndigoProductAssetViewModel | null,
	subProducts: Array<SyndigoSubProductViewModel>,
	dateCreated: string,
	dateUpdated: string,
}

export type MapStringToSyndigoProductAssetViewModel = {
	[key in string]: SyndigoProductAssetViewModel
}

export interface SyndigoProductAssetViewModel {
	url: string,
	fileName: string,
	format: string,
}

export interface SyndigoSubProductViewModel {
	id: number,
	gtin: string,
	upc: string,
	syndigoDateUpdated: string,
	attributes?: MapStringToString | null,
	assets?: MapStringToSyndigoProductAssetViewModel | null,
}

export interface VendorImportResultView {
	vendorId: number,
	vendorCode: string,
	vendorName: string,
	shipFromCodes: Array<string>,
}

export interface JobTriggerData {
	jobKey: string,
}

export interface ScheduledJobInfo {
	jobKey: string,
	lastStarted?: string | null,
	lastFinished?: string | null,
	isLocked?: boolean | null,
	lockExpirationSeconds?: number | null,
	auditInfo?: string | null,
}
//generated types end

export type MapStringToString = {
    [key in string]: string
}
