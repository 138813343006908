import { FC } from "react";
import {HoverCard, HoverCardContent, HoverCardTrigger, Tooltip, TooltipContent, TooltipTrigger} from "~/Components/UI";
import {
  TbAlertCircle,
  TbAlertTriangle,
  TbCalculator, TbHelpCircleFilled,
  TbNote,
} from "react-icons/tb";
import API, {
  LogisticsCostView,
  MarginView,
  MaterialCostPriceView,
} from "~/API";
import useSWR from "swr";
import { cn, trimUndefined } from "~/Utils";
import { LinearLoader, Skeleton } from "~/Components/Loaders";
import { useIsInternalCustomer } from "~/Reducers/User";
import {decimalToHundredthPercent} from "~/Utils/UnitConversion";

export interface CalculatePriceModel {
  productId: number;
  quoteUOM: string;
  currency: string;
  lengthId: number[];
  incotermDestination?: string;
  shipFromPortId?: number;
  shipToPortId?: number;
  shipToLocationId?: number;
  offerId: number;
  offerProductId?: number;
}

export const CalculatedPriceTooltip: FC<{
  priceModel: CalculatePriceModel;
  price: number;
  showAdaptedPrice: boolean;
  changeCalculatedPrice?: (totalValue: number, subTotal: number, validTotal: boolean) => void;
}> = (props) => {
  const isInternalCustomer = useIsInternalCustomer();

  const priceCalculation = useSWR(
    ["api/price/calculation", props.priceModel],
    async () => {
      const calc = await API.calculateQuoteItemPrice(
        // @ts-ignore
        trimUndefined({
          isInternalCustomer,
          ...props.priceModel,
        }),
      );

      const subTotal = (calc.materialCostView?.materialCostPerQuoteUom || 0) + (calc.logisticsCostView?.totalCostsPerUom || 0)
      const validTotal = calc.materialCostView?.materialCostPerQuoteUom > 0 && calc.logisticsCostView?.totalCostsPerUom > 0;
      props.changeCalculatedPrice?.(calc.totalItemPricePerUnit, subTotal, validTotal);
      return calc;
    },
    { revalidateOnFocus: false }
  );

  const formatPrice = (price: number) =>
    Intl.NumberFormat("en-US", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      style: "currency",
      currency: props.priceModel.currency,
    }).format(price);

  const adaptedPriceDisplay = formatPrice(props.price);
  const priceDisplay = formatPrice(priceCalculation.data?.totalItemPricePerUnit || 0);

  if (priceCalculation.isLoading && !props.showAdaptedPrice)
    return (
      <Skeleton className="w-max-4xl w-full h-line-md" />
    );

  if (priceCalculation.error)
    return (
      <span className="flex-row gap-xxs justify-end align-center text-bold color-orange-1 cursor-pointer">
        Error!
        <TbCalculator size={20} />
      </span>
    );

  return (
    <HoverCard openDelay={350}>
      <HoverCardTrigger>
        <span className="flex-row gap-xxs justify-end align-center text-bold color-orange-1 cursor-pointer">
          {props.showAdaptedPrice ? adaptedPriceDisplay : priceDisplay}
          <TbCalculator size={20} />
        </span>
      </HoverCardTrigger>
      <HoverCardContent>
        {priceCalculation.isLoading && <LinearLoader loading />}
        {!priceCalculation.isLoading && (
          <div className="flex-col gap-md w-min-9xl w-max-10xl m-sm overflow-y-auto h-max-60vh relative">
            <div className="flex-col gap-xxs">
              <div className="text-xl sticky top-0 bg-black-12">Price Calculation</div>

              {priceCalculation.data?.errors.map((err) => (
                <Banner type="error" text={err} key={err} />
              ))}

              {priceCalculation.data?.warnings.map((err) => (
                <Banner type="warning" text={err} key={err} />
              ))}
            </div>

            <MaterialCostSection
              formatPrice={formatPrice}
              mat={priceCalculation.data?.materialCostView}
              uom={props.priceModel.quoteUOM}
            />

            <LogisticsCostSection
              formatPrice={formatPrice}
              log={priceCalculation.data?.logisticsCostView}
            />

            <PriceRow
              label="Subtotal"
              price={formatPrice((priceCalculation.data?.materialCostView?.materialCostPerQuoteUom || 0) + (priceCalculation.data?.logisticsCostView?.totalCostsPerUom || 0))}
              className="text-bold pb-xs text-lg"
            />

            <MarginCostSection
              formatPrice={formatPrice}
              mrg={priceCalculation.data?.marginView}
              mat={priceCalculation.data?.materialCostView}
              log={priceCalculation.data?.logisticsCostView}
            />

            <div className="pt-xs px-xs flex-row justify-between text-bold text-lg sticky bottom-0 bg-black-12">
              <div>Total</div>
              <div className="color-orange-1">
                {priceDisplay}
                {` / ${props.priceModel.quoteUOM}`}
              </div>
            </div>
          </div>
        )}
      </HoverCardContent>
    </HoverCard>
  );
};

const MaterialCostSection: FC<{
  uom: string;
  mat: MaterialCostPriceView  | undefined;
  formatPrice: (price: number) => string;
}> = (props) => {
  if (props.mat == undefined)
    return null;

  return (
    <div className="flex-col gap-xxs">
      {props.mat.warnings?.map((warn) => (
        <Banner type="warning" text={warn} key={warn} />
      ))}

      {props.mat.notes?.map((note) => (
        <Banner type="note" text={note} key={note} />
      ))}

      {props.mat.baseUom != props.uom && (
        <PriceRow
          label={`Base Material Cost (${props.mat.baseUom})`}
          price={props.formatPrice(props.mat.materialCostPerBaseUom)}
        />
      )}

      <PriceRow
        label={`Material Cost (${props.uom})`}
        price={props.formatPrice(props.mat.materialCostPerQuoteUom)}
        className="border-b-1 border-black-10 text-bold pb-xs"
        calculationSteps={props.mat.calculations}
    />
</div>
)
  ;
};

const LogisticsCostSection: FC<{
  log: LogisticsCostView;
  formatPrice: (price: number) => string;
}> = (props) => {
  if (props.log == undefined)
    return null;

  return (
    <div className="flex-col gap-xxs">
      {props.log.errors?.map((err) => (
        <Banner type="error" text={err} key={err} />
      ))}

      {props.log.warnings?.map((warn) => (
        <Banner type="warning" text={warn} key={warn} />
      ))}

      {(props.log.portOfLoadingName || props.log.portOfDischargeName) && (
        <Banner type="note" text={`Port of Loading: ${props.log.portOfLoadingName || "-"}, Port of Discharge: ${props.log.portOfDischargeName || "-"}`} />
      )}

      {props.log.logisticsCostPerUom != null && (
        <PriceRow
          price={props.formatPrice(props.log.logisticsCostPerUom)}
          label="Logistics Costs Per UOM"
          calculationSteps={props.log.calculations}
        />
      )}

      {props.log.addOnCosts.map((addon) => (
        <PriceRow
          price={props.formatPrice(addon.totalAddOnCostsPerUom)}
          label={addon.label}
          calculationSteps={[
            ...addon.addOns?.map((a) => `${a.name} - ${props.formatPrice(a.cost)}/${a.per}`),
            addon.calculation
          ]}
        />
      ))}

      {props.log.duties?.duties?.map((duty) => (
        <PriceRow
          price={props.formatPrice(duty.dutyCostPerUom)}
          label={`${duty.name} (Duty)`}
          calculationSteps={[duty.calculation]}
          key={duty.name}
        />
      ))}

      <PriceRow
        label="Logistics Cost"
        price={props.formatPrice(props.log.totalCostsPerUom || 0)}
        className="border-b-1 border-black-10 text-bold pb-xs"
        calculationSteps={props.log.calculations}
      />
    </div>
  );
}

const MarginCostSection: FC<{
  mat: MaterialCostPriceView  | undefined;
  mrg: MarginView | undefined;
  log: LogisticsCostView | undefined;
  formatPrice: (price: number) => string;
}> = (props) => {
  if (props.mrg == undefined)
    return (
      <PriceRow
        label="Margin Cost"
        price="$0.00"
        className="border-b-1 border-black-10 text-bold pb-xs"
      />
    );

  return (
    <div className="flex-col gap-xxs">
      {props.mrg.warnings?.map((warn) => (
        <Banner type="warning" text={warn} key={warn} />
      ))}

      {props.mrg.notes?.map((note) => (
        <Banner type="note" text={note} key={note} />
      ))}

      <PriceRow
        label="Margin"
        price={`${props.formatPrice(props.mrg.marginCostPerUom)} (${decimalToHundredthPercent(props.mrg.marginPercent)}%)`}
        className="border-b-1 border-black-10 text-bold pb-xs"
      />
    </div>
  );
}

const Banner: FC<{
  text: string;
  type: "error" | "warning" | "note";
}> = (props) => (
  <div
    className={cn(
      props.type == "error" && "color-red-1 bg-red-6",
      props.type == "warning" && "color-orange-0 bg-orange-9",
      props.type == "note" && "color-black-4 bg-black-11",
      "text-semibold flex-row align-center gap-xs px-sm py-xs rounded-xxs text-sm",
    )}
  >
    <div className="flex-row align-center">
      {props.type == "error" && <TbAlertCircle size={18} />}
      {props.type == "warning" && <TbAlertTriangle size={18} />}
      {props.type == "note" && <TbNote size={18} />}
    </div>
    <span>{props.text}</span>
  </div>
);

const PriceRow: FC<{
  label: string;
  price: string;
  uom?: string;
  className?: string;
  calculationSteps?: string[];
}> = (props) => (
  <div className={cn(props.className, "px-xs flex-row justify-between text-md")}>
    <div className="flex-row gap-xxs">
      <span>{props.label}</span>
      {props.calculationSteps && props.calculationSteps.length > 0 && (
        <Tooltip>
          <TooltipTrigger>
            <span className="cursor-pointer color-black-3">
              <TbHelpCircleFilled size={20} />
            </span>
          </TooltipTrigger>
          <TooltipContent>
            <ul className="w-max-10xl">
              {props.calculationSteps.map((calc) => (
                <li key={calc}>{calc}</li>
              ))}
            </ul>
          </TooltipContent>
        </Tooltip>
      )}
    </div>
    <div className="color-orange-1">
      {props.price}
      {props.uom && ` / ${props.uom}`}
    </div>
  </div>
);
