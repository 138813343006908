import { FC, useState } from "react";
import {
  Button,
  Dialog,
  ConfirmDialog,
  Card,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  Table,
  ButtonFileUpload,
} from "~/Components/UI";
import { SeaFreightCostImportRow } from "~/API";
import {
  useImportLogisticsCost,
  useValidateLogisticsCostssImport,
} from "~/Data/LogisticsCosts";
import { TbCheck, TbInfoCircle, TbUpload, TbX } from "react-icons/tb";
import { ScrollPage } from "~/Components/Layout/Pages";
import BottomButtonsPanel from "~/Components/Layout/BottomButtonsPanel";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ls } from "~/Locales";
import { useRouteNameForLocationCost } from "~/Pages/Logistics/LogisticsCosts/LogisticsCostsPage";
import Toast from "~/Components/Toast";
import { PageTitle } from "~/Components/Nav/PageTitle";
import { seaFreightCostsRootPath } from "~/Services/Routes";
import {IncotermView} from "~/Components/Views/IncotermView";
import PriceView from "~/Components/Views/PriceView";

const ImportLogisticsCostsTab: FC = () => {
  const [errorMsg, setErrorMsg] = useState(null);
  const [parsedData, setParsedData] = useState(null);
  const [validated, setValidated] = useState(false);
  const [fileName, setFileName] = useState("");

  const validateImport = useValidateLogisticsCostssImport();

  const importCosts = useImportLogisticsCost();

  function clearData() {
    setErrorMsg("");
    setParsedData(null);
    setFileName(null);
  }

  const handleFileChange = async (files: File[]) => {
    if (files.length > 0) {
      clearData();
      const file = files[0];
      setFileName(file.name);

      const reader = new FileReader();
      reader.onload = async (e) => {
        const csvContent = e.target.result;
        const importCostsRows = await parseData(csvContent.toString());
        const finalParsedData = await validateData(importCostsRows);
        setParsedData(finalParsedData);
      };
      reader.readAsText(file);
    }
  };

  const parseData = async (
    data: string,
  ): Promise<SeaFreightCostImportRow[]> => {
    const lines = data.split("\n");

    const headers = lines.shift().trim().split(",");
    if (!validateHeaders(headers)) return null;

    const parsedData: SeaFreightCostImportRow[] = [];

    for (let i = 0; i < lines.length; i++) {
      const values = lines[i].trim().split(",");
      if (values.length === headers.length) {
        // Create an object with keys from headers and corresponding values
        let rowData: SeaFreightCostImportRow = {
          id: parseInt(values[headers.indexOf("ID (Read Only)")].trim()),
          portOfDischargeId: 0,
          portOfLoadingId: 0,
          category: values[headers.indexOf("Category")].trim(), //
          cost: parseFloat(values[headers.indexOf("Cost Per 40'")].trim()), //
          currency: values[headers.indexOf("Currency")].trim(), //
          destinationDischargeCode: values[headers.indexOf("Destination Discharge - Code")].trim(),
          destinationLoadingCode: values[headers.indexOf("Origin Loading - Code")].trim(),
          incotermDischarge: values[headers.indexOf("Incoterm Discharge")].trim(), //
          incotermLoading: values[headers.indexOf("Incoterm - Loading")].trim(), //
          portOfLoadingCode: values[headers.indexOf("Port of Loading - Code")].trim(), // route
          portOfDischargeCode: values[headers.indexOf("Port of Discharge - Code")].trim(), // route
          validDate: values[headers.indexOf("Valid Date")].trim(), //
          vendor: values[headers.indexOf("Vendor")].trim(), // forwarder
          transitDurationDays: parseInt(values[headers.indexOf("Transit Time")].trim()),
          notes: values[headers.indexOf("Notes")].trim(),
          invalid: false,
          invalidMsg: "",
        };
        parsedData.push(rowData);
      }
    }
    return parsedData;
  };

  function validateHeaders(headers: string[]): boolean {
    const requiredFields: Set<string> = new Set([
      "Incoterm - Loading",
      "Port of Loading - Code",
      "Origin Loading - Code",
      "Incoterm Discharge",
      "Port of Discharge - Code",
      "Destination Discharge - Code",
      "Transit Time",
      "Cost Per 40'",
      "Currency",
      "Category",
      "Valid Date",
      "Vendor",
      "ID (Read Only)",
      "Notes",
    ]);

    const valid =
      requiredFields.size ===
      headers.filter((field) => requiredFields.has(field)).length;
    if (!valid) {
      const missingFields: string[] = Array.from(requiredFields).filter(
        (field) => !headers.includes(field),
      );
      console.log(missingFields);
      setErrorMsg("Missing one or more headers:" + missingFields);
    }
    return valid;
  }

  const validateData = async (
    parsedData: SeaFreightCostImportRow[],
  ): Promise<SeaFreightCostImportRow[]> => {
    if (!parsedData) return null;
    const result = await validateImport.call(parsedData);
    setValidated(allRowsValid(result));
    return result;
  };

  function allRowsValid(parsedData: SeaFreightCostImportRow[]): boolean {
    for (const row of parsedData) {
      if (row.invalid) {
        setErrorMsg("One or more rows are invalid. Fix csv and re-upload.");
        return false;
      }
    }
    return true;
  }

  const confirmImport = async () => {
    try {
      await importCosts.call(parsedData);
      clearData();
      Toast.Success("Imported Logistics Costs");
    } catch (e) {
      Toast.ApiError(e);
      throw e;
    }
  };

  const table = useReactTable({
    data: parsedData || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnPinning: {
        left: ["valid", "route"]
      }
    }
  });

  return (
    <ScrollPage title="Import Logistics Costs">
      <PageTitle
        type="back"
        parentUrl={seaFreightCostsRootPath}
        title="title.logistics.costs.import"
      />

      <Card className="flex-row gap-md align-center">
        <ButtonFileUpload
          onSubmitted={handleFileChange}
          acceptedFileTypes={[".csv"]}
          label="title.upload.csv"
          icon={<TbUpload size={20} />}
        />
        {fileName && (
          <div className="flex-row gap-xs">
            <div className="text-bold">Import File:</div>
            <div>{fileName}</div>
          </div>
        )}

        <HelpDialog />
      </Card>

      {parsedData && (
        <>
          <Table table={table} />

          <BottomButtonsPanel>
            {errorMsg && <div className={"color-red-1 text-lg"}>{errorMsg}</div>}

            <ConfirmDialog
              onConfirm={confirmImport}
              title="title.logistics.costs.import"
              trigger={<Button disabled={!validated} label="title.submit" />}
            >
              <div className="text-semibold desktop-w-max-10xl">
                Confirm import of {parsedData.length} logistics cost record(s).
                This will overwrite the data for all cost records included in
                the CSV.

                <div className="mt-md text-bold">
                  It is recommended to have a backup CSV of original values, as
                  this action cannot be undone.
                </div>
              </div>
            </ConfirmDialog>
          </BottomButtonsPanel>
        </>
      )}
    </ScrollPage>
  );
};

const HelpDialog: FC = () => (
  <Dialog
    title={"Bulk Logistics Costs Import Help"}
    trigger={
      <Button
        variant="text"
        leftIcon={<TbInfoCircle size={20} />}
        className="ml-auto"
        label="title.help"
      />
    }
  >
    <div className="desktop-w-max-10xl text-left">
      <h1 className="text-lg text-bold">CSV Formatting Tips</h1>
      <ol type="1" className="text-semibold">
        <li>
          Use the 'export' feature from the 'Logistics Costs' tab to export a
          csv based on the filtered data. The entire non-paginated data set for
          the filters will be exported.
        </li>
        <li>
          CSV file must include all of the headers that are in the exported csv.
        </li>
        <li>Date must be in MM/DD/YYYY format.</li>
        <li>
          Each record must have a valid TimberBase ID in the 'ID (Read Only)'
          field.
        </li>
        <li>A matching logistics cost record must be found based on the ID.</li>
      </ol>

      <h1 className="text-lg text-bold">Import Instructions</h1>
      <ol type="1" className="text-semibold">
        <li> Click "Upload CSV File" and select your csv.</li>
        <li>
          The csv will be automatically validated If it is good, click the
          "Submit" button, and then "Confirm" in the dialog.
        </li>
        <li>
          If there are validation errors, edit the csv to fix them and re-upload
          it.
        </li>
        <li>
          Hover over the X icon in the VALID column to view row-specific
          validation errors.
        </li>
      </ol>
    </div>
  </Dialog>
);

export default ImportLogisticsCostsTab;

const ch = createColumnHelper<SeaFreightCostImportRow>();

const columns = [
  ch.display({
    id: "valid",
    header: "Valid",
    cellMemo: ({ row }) =>
      row.invalid ? (
        <Tooltip>
          <TooltipTrigger>
            <div className="color-red-1">
              <TbX size={24} />
            </div>
          </TooltipTrigger>
          <TooltipContent>{row.invalidMsg}</TooltipContent>
        </Tooltip>
      ) : (
        <TbCheck size={20} className="color-green-1" />
      ),
    size: 72,
  }),
  ch.display({
    id: "route",
    header: ls("title.route"),
    cellMemo: ({ row }) => (
      <div className="text-no-wrap">
        {useRouteNameForLocationCost(row as any)}
      </div>
    ),
  }),
  ch.accessor("incotermLoading", {
    header: ls("title.incoterm.-.loading"),
    cellMemo: ({ value }) => (
      <div className="text-no-wrap">
        <IncotermView incoterm={value as any} />
      </div>
    ),
  }),
  ch.accessor("portOfLoadingCode", {
    header: ls("title.port.of.loading"),
  }),
  ch.accessor("destinationLoadingCode", {
    header: ls("title.origin.loading"),
  }),
  ch.accessor("incotermDischarge", {
    header: ls("title.incoterm.-.discharge"),
    cellMemo: ({ value }) => (
      <div className="text-no-wrap">
        <IncotermView incoterm={value as any} />
      </div>
    ),
  }),
  ch.accessor("portOfDischargeCode", {
    header: ls("title.port.of.discharge"),
  }),
  ch.accessor("destinationDischargeCode", {
    header: ls("title.destination.discharge"),
  }),
  ch.accessor("transitDurationDays", {
    header: ls("title.transit.time"),
  }),
  ch.accessor("cost", {
    header: ls("title.cost.per.40"),
    cellMemo: ({ value, row }) => (
      <PriceView
        price={value}
        currency={row.currency}
      />
    )
  }),
  ch.accessor("currency", {
    header: ls("title.currency"),
  }),
  ch.accessor("category", {
    header: ls("title.category"),
    cellMemo: ({ value }) => (
      <div className="text-no-wrap">
        {value == null ? "-" : ls(`logistics.cost.category.${value}` as any)}
      </div>
    )
  }),
  ch.accessor("validDate", {
    header: ls("title.validity.date"),
  }),
  ch.accessor("vendor", {
    header: ls("title.vendor"),
    cellMemo: ({ value }) => <div className="text-no-wrap">{value}</div>,
  }),
  ch.accessor("notes", {
    header: ls("title.rate.notes"),
    cellMemo: ({ value }) => (
      <div className="w-min-9xl">
        {value}
      </div>
    )
  }),
];
