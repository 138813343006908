import { Link } from "react-router-dom";
import { LC } from "~/Locales";
import {useSalesOrder, useSalesOrderInvoices} from "~/Data/Orders/SalesOrders";
import { useIsInternalUser } from "~/Reducers/User";
import {UserName} from "~/Components/Users/UserName";
import {cn} from "~/Utils";
import {
  getPathForPO,
  quotesRootPath,
} from "~/Services/Routes";
import PriceView from "~/Components/Views/PriceView";
import { FC } from "react";
import { Divider, HDivider } from "~/Components/UI";
import LocationView from "~/Components/Locations/LocationView";
import {SalesOrderStatusBadge} from "~/Components/Orders/SalesOrderStatusBadge";

const OrderHeader: FC<{ orderId: number }> = ({ orderId }) => {
  const isInternalUser = useIsInternalUser();
  const salesOrder = useSalesOrder(orderId);

  const {
    salesPersonUserId,
    number,
    buyerStatus,
    currency,
    buyerReferenceNumber,
    paymentMethod,
    accountManagerUserId,
    locationId,
    directPoNumber,
    purchaseOrderId,
    totalSalesForCommission,
    shipTerms,
    shipTermsLoc,
    paid,
    quoteId,
    prepaidOrderAmount,
  } = salesOrder.data || {};

  const buyerReference = (() => {
    if (buyerReferenceNumber && quoteId)
      return (
        <a
          href={`${quotesRootPath}${quoteId}`}
          target="_blank"
          rel="noreferrer noopener"
        >
          {buyerReferenceNumber}
        </a>
      );

    if (buyerReferenceNumber) return buyerReferenceNumber;

    return "-";
  })();

  const { data: invoices } = useSalesOrderInvoices(orderId);
  const showPaidInFull = invoices?.some((i) => i.paymentCount > 0);

  let shipTermsDisplay =
    (shipTerms && shipTermsLoc
      ? `${shipTerms} - ${shipTermsLoc}`
      : shipTerms) || "-";

  return (
    <div className="flex-row flex-wrap p-sm text-left">
      {isInternalUser && (
        <>
          <div className="flex flex-col align-start my-sm mx-lg">
            <div className="text-sm color-text-3">
              <LC id="title.ship.to" />
            </div>
            <LocationView id={locationId} className="mt-xxs" />
            <div className="text-sm mt-md color-text-3">
              <LC id="title.sales.order.#" />
            </div>
            <div className="mt-xxs">{number || "-"}</div>
          </div>
          <HDivider className="mobile-hide" />
        </>
      )}

      <div className="flex flex-col align-start my-sm mx-lg mobile-flex-basis-full">
        <div className="text-sm color-text-3">
          <LC id="title.status" />
        </div>
        <div className="my-xxs">
          <SalesOrderStatusBadge status={buyerStatus} />
        </div>
        <div className="text-sm mt-md color-text-3">
          <LC id="title.salesperson" />
        </div>
        <div className="mt-xxs">
          <UserName id={accountManagerUserId} />
        </div>
        {salesPersonUserId !== accountManagerUserId && (
          <div className="mt-xxs">
            <UserName id={salesPersonUserId} />
          </div>
        )}
      </div>

      <HDivider className="mobile-hide" />
      <div className="flex flex-col align-start my-sm mx-lg mobile-flex-basis-full">
        {!isInternalUser && (
          <>
            <div className="text-sm color-text-3">
              <LC id="title.sales.order.#" />
            </div>
            <div className="mt-xxs">{number || "-"}</div>
          </>
        )}
        <div
          className={cn(
            "text-sm color-text-3",
            !isInternalUser && "mt-md",
          )}
        >
          <LC id="title.customer.po.#" />
        </div>
        <div className="mt-xxs">{buyerReference}</div>
        {isInternalUser && (
          <>
            <div className="text-sm mt-md color-text-3">
              <LC id="title.linked.purchase.order.#" />
            </div>
            <div className="mt-xxs">
              {!!purchaseOrderId &&
              !!directPoNumber &&
              directPoNumber !== "0" ? (
                <Link to={getPathForPO(purchaseOrderId)} className={"Link"}>
                  {directPoNumber}
                </Link>
              ) : !!directPoNumber && directPoNumber !== "0" ? (
                directPoNumber
              ) : (
                "-"
              )}
            </div>
          </>
        )}
      </div>

      <HDivider className="mobile-hide" />
      <div className="flex flex-col align-start my-sm mx-lg mobile-flex-basis-full">
        <div className="text-sm color-text-3">
          <LC id="title.payment.terms" />
        </div>
        <div className="mt-xxs">{paymentMethod}</div>
        <div className="text-sm mt-md color-text-3">
          <LC id="title.ship.terms" />
        </div>
        <div className="ValueColor mt-xxs">{shipTermsDisplay}</div>
      </div>

      <HDivider className="mobile-hide" />
      <div className="flex flex-col align-start my-sm mx-lg mobile-flex-basis-full">
        <div className="text-sm color-text-3">
          <LC id="title.amount" />
        </div>
        <div className="mt-xxs flex-row gap-sm w-full justify-between">
          <LC id="title.total.sales.value" />:
          <PriceView price={totalSalesForCommission} currency={currency} className="text-mono" />
        </div>
        <PaidIndicator
            paid={paid}
            prepaid={prepaidOrderAmount}
            showPaidInFull={showPaidInFull}
            currency={currency} />
      </div>

      <Divider />
    </div>
  );
};

const PaidIndicator: FC<{
  paid: boolean | null;
  prepaid: number;
  showPaidInFull: boolean;
  currency: string;
}> = (props) => {
  if (!props.showPaidInFull || props.paid == null) {
    return (
      <div className="mt-xs flex-row gap-sm w-full justify-between">
        <LC id="order.prepaid" />:
        <PriceView price={props.prepaid} currency={props.currency} className="text-mono" />
      </div>
    );
  }

  return (
    <div className="mt-xs flex-row gap-sm w-full justify-between">
      <LC id="title.paid.in.full" />:
      <span>
        <LC id={props.paid ? "phase.yes" : "phase.no"} />
      </span>
    </div>
  );
};

export default OrderHeader;
