import { FC } from "react";
import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { companiesRootPath } from "~/Services/Routes";
import CompanyView from "~/Components/Views/CompanyView";
import { useIsAdmin } from "~/Reducers/User";
import { ScrollPage } from "~/Components/Layout/Pages";
import { PageTitle } from "~/Components/Nav/PageTitle";
import { PageTab, PageTabContainer } from "~/Components/Nav/PageTabs";
import { useCompany } from "~/Data/Companies/Companies";
import { LoadingPage } from "~/Pages/ErrorPages/LoadingPage";
import EndpointErrorPage from "~/Pages/ErrorPages/EndpointErrorPage";
import { CompanyType } from "~/API";

import ImportProductsTab from "./Components/ImportProductsTab";
import CompanyDetailsGeneralTab from "./Components/CompanyDetailsGeneralTab";
import CompanyDetailsUsersTab from "./Components/CompanyDetailsUsersTab";
import CompanyDetailsMyProductsTab from "./Components/CompanyDetailsMyProductsTab";
import CompanyDetailsLocationsTab from "./Components/CompanyDetailsLocationsTab";

const generalPath = `/general`;
const locationsPath = `/locations`;
const usersPath = `/users`;
const myProductsPath = `/my-products`;
const importProductsPath = `/import-products`;

const CompanyDetailsPage: FC = () => {
  const { id: companyId } = useParams<{ id: string }>();
  const match = useRouteMatch();
  const isAdmin = useIsAdmin();
  const company = useCompany(+companyId);
  const isVendor = company.data?.type == CompanyType.SELLER

  if (company.isLoading)
    return (
      <LoadingPage />
    );

  if (company.error)
    return (
      <EndpointErrorPage
        status={company.error.status}
        item="company"
      />
    );

  return (
    <ScrollPage title={company.data.legalName}>
      <PageTitle type="back" parentUrl={companiesRootPath} />

      <PageTabContainer title={<CompanyView id={+companyId} />}>
        <PageTab label="title.general" route={match.url + generalPath} replace />
        <PageTab
          label={isVendor ? "title.ship.froms" : "title.ship.tos"}
          route={match.url + locationsPath}
          replace
        />
        <PageTab label="title.users" route={match.url + usersPath} replace />

        <PageTab
          label="title.my.products"
          route={match.url + myProductsPath}
          replace
        />

        {isAdmin && (
          <PageTab
            label="title.import.products"
            route={match.url + importProductsPath}
            replace
          />
        )}
      </PageTabContainer>

      <Switch>
        <Redirect
          exact
          from={`${companiesRootPath}${companyId}`}
          to={`${companiesRootPath}${companyId}/general`}
        />
        <Route path={match.url + generalPath}>
          <CompanyDetailsGeneralTab company={company.data} mutateCompany={company.mutate} />,
        </Route>
        <Route path={match.url + locationsPath}>
          <CompanyDetailsLocationsTab company={company.data} />
        </Route>
        <Route path={match.url + usersPath}>
          <CompanyDetailsUsersTab company={company.data} />
        </Route>

        <Route path={match.url + myProductsPath}>
          <CompanyDetailsMyProductsTab
            isVendor={isVendor}
            id={company.data.id}
          />
        </Route>

        {isAdmin && (
          <Route path={match.url + importProductsPath}>
            <ImportProductsTab company={company.data} />
          </Route>
        )}
      </Switch>
    </ScrollPage>
  );
};

export default CompanyDetailsPage;
