import useSWR from "swr";
import API, {DeliveryBuyerStatus, Order, SalesOrderView} from "~/API";
import { useMutateHook } from "~/Hooks";
import useSWRInfinite from "swr/infinite";

export const useDashboardSalesOrders = (isInternal: boolean) =>
  useSWR(["api/salesOrders/dashboard", isInternal], async () => {
    if (isInternal) {
      return await API.getSalesOrders({ limit: 10, onlyMyOrders: true });
    } else {
      return await API.getSalesOrdersCustomer({ limit: 10 });
    }
  });

export const useSalesOrder = (id: number) =>
  useSWR(["api/sales/order", id], async () => await API.getSalesOrder({ id }), { errorRetryCount: 0 });

export const useSalesOrderInvoices = (id: number) => useSWR(
  ["api/sales/orders/invoices"],
  () => API.getSalesOrderInvoices({ id })
);

export interface SalesOrderFilters {
  search?: string;
  locations?: Array<number>;
  customer?: Array<number>;
  status?: Array<DeliveryBuyerStatus>;
  accountManager?: Array<number>;
  deliveryDateFrom?: string;
  deliveryDateTo?: string;
  companyCreateDateFrom?: string;
  companyCreateDateTo?: string;
  onlyMyOrders?: boolean;
  query?: string;
  orderBy?: string;
  order?: Order;
  offset?: number;
  limit?: number;
}

const getSOListKey = (
  filters: SalesOrderFilters,
  offset: number,
  previousPageData: SalesOrderView[] | undefined,
) => {
  if (previousPageData && !previousPageData.length) return null;
  return { route: "api/sales/orders", filters, offset };
};

const PAGE_SIZE = 40;

export const useSalesOrders = (isInternal: boolean, filters: SalesOrderFilters) =>
  useSWRInfinite(
    (i, p) => getSOListKey(filters, i * PAGE_SIZE, p),
    (key) =>
      isInternal
        ? API.getSalesOrders({
            ...filters,
            offset: key.offset,
            limit: PAGE_SIZE,
          })
        : API.getSalesOrdersCustomer({
            ...filters,
            offset: key.offset,
            limit: PAGE_SIZE,
          }),
  );

export const useAddSalesOrderMember = (orderId: number) =>
  useMutateHook<number, any, any>(["api/sales/order", orderId], async (args) =>
    API.addSalesOrderMember({ id: orderId, userId: args }),
  );

export const useRemoveSalesOrderMember = (orderId: number) =>
  useMutateHook<number, any, any>(["api/sales/order", orderId], async (args) =>
    API.removeSalesOrderMember({ id: orderId, userId: args }),
  );
